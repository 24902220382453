#survey-detail-page {
    .tab3-container {
        margin-top: 20px;
        padding: 20px;
        border-radius: 20px;
        border: solid 2px #eff1f5;
        background-color: #ffffff;
        .header {
            color: #1050a2;
            font-size: 16px;
            font-family: Kanit-Regular;
            font-weight: bold;
        }
        .title-question {
            padding: 10px;
            border-bottom: 1px solid #efefef;
            margin-bottom: 20px;
        }
        .switch-text {
            font-size: 16px !important;
        }
        .ant-row {
            margin-bottom: 10px;
            flex-flow: inherit;
        }
        .editor-pen {
            // padding-top: 10px;
            display: flex;
            align-items: center;
            padding-top: 20px;
            img {
                width: 20px;
            }
        }
        textarea.ant-input {
            background-color: #ffffff;
            margin-top: 15px;
            padding: 10px 15px;
        }
    }
    .bottom-send-button-container {
        left: 0px !important;
    }
}
