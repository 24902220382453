// button
.btn-brown {
  background-color: #e3d6bd !important;
  box-shadow:
    4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
    -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
    inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
    inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05) !important;
  color: #847049 !important;
  background-image: none !important;
}

.button-theme {
  padding: 0px 30px;
}

.button-theme-brown {
  object-fit: contain !important;
  border-radius: 15px !important;
  box-shadow:
    4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
    -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
    inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
    inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05) !important;
  background-color: #e3d6bd !important;
  height: 60px !important;
  font-size: 20px !important;
  color: #847049 !important;
  border: none !important;
}

.button-theme-brown-45 {
  object-fit: contain !important;
  border-radius: 10px !important;
  box-shadow:
    4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
    -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
    inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
    inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05) !important;
  background-color: #e3d6bd !important;
  height: 45px !important;
  font-size: 16px !important;
  color: #847049 !important;
  border: none !important;
  min-width: 120px;
}

.button-theme-blue {
  object-fit: contain !important;
  border-radius: 15px !important;
  // box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
  //   -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
  //   inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
  //   inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15) !important;
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4) !important;
  height: 60px !important;
  font-size: 20px !important;
  color: #fff !important;
  border: none !important;
}

.button-theme-green-45 {
  object-fit: contain !important;
  border-radius: 10px !important;
  background-image: linear-gradient(to top, #438940, #438940, #438940) !important;
  height: 45px !important;
  font-size: 18px !important;
  color: #fff !important;
  border: none !important;
  min-width: 120px;
}

.button-theme-blue-45 {
  object-fit: contain !important;
  border-radius: 10px !important;
  // box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
  //   -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
  //   inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
  //   inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15) !important;
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4) !important;
  height: 45px !important;
  font-size: 18px !important;
  color: #fff !important;
  border: none !important;
  min-width: 120px;
}

.input-theme {
  border-radius: 5px !important;
  border: solid 1px #dbdcde !important;
  background-color: #fefefe !important;
  object-fit: contain !important;
  height: 50px !important;
  input.ant-input {
    font-size: 18px !important;
  }
}

.input-theme-small {
  border-radius: 5px !important;
  border: solid 1px #dbdcde !important;
  background-color: #fefefe !important;
  object-fit: contain !important;
  height: 40px !important;
  input.ant-input {
    font-size: 14px !important;
  }
}

.textarea-theme-small {
  border-radius: 5px !important;
  border: solid 1px #dbdcde !important;
  background-color: #fefefe !important;
  object-fit: contain !important;
  min-height: 40px;
  textarea.ant-input {
    font-size: 14px !important;
  }
}

.ant-select-sm {
  .ant-select-selector {
    border-radius: 5px !important;
    border: solid 1px #dbdcde !important;
    background-color: #fefefe !important;
    object-fit: contain !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    font-size: 14px !important;
  }
}

.ant-select-disabled {
  .ant-select-selector {
    border: solid 1px #dbdcde !important;
    background-color: #e2e4eb !important;
  }
}

.ant-input[disabled] {
  // border: solid 1px #dbdcde !important;
  background-color: #e2e4eb !important;
}
.ant-input-affix-wrapper-disabled {
  border: solid 1px #dbdcde !important;
  background-color: #e2e4eb !important;
}

.label-grey {
  color: #6d7481;
  font-size: 18px;
}

// .ant-checkbox-checked::after {
//   background-color: #0c4da2 !important;
// }

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0c4da2 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #0c4da2 !important;
}

.ant-form-item input[type="radio"],
.ant-form-item input[type="checkbox"] {
  height: 25px !important;
  width: 25px !important;
}

.ant-checkbox-inner {
  width: 25px !important;
  height: 25px !important;
}

.ant-checkbox-inner::after {
  width: 8px !important;
  height: 12px !important;
}

.text-link {
  cursor: pointer;
  text-decoration: underline;
  color: #0c4ca3;
}

// .ant-checkbox-inner {
//   border: 0.5px solid #efefef !important;
// }

// .ant-checkbox-checked .ant-checkbox-inner::after {
//   border: none !important;
// }
.ant-input-prefix {
  img {
    width: 18px !important;
    height: 20px !important;
    margin-right: 14px;
    padding-right: initial !important;
  }
}

.ant-tag {
  font-size: 14px !important;
  padding: 5px 10px !important;
}

.alert-warning {
  padding: 10px;
  border-radius: 10px;
  background-color: #fb9677;
  color: white;
  font-weight: bold;
}

textarea.ant-input:read-only {
  background-color: #efefef !important;
}

.text-green {
  color: #488345 !important;
}
.bg-green {
  background-color: #488345 !important;
}
.text-blue {
  color: #0e4ea3 !important;
}
.bg-blue {
  background-color: #0e4ea3 !important;
}
.bg-blue-50 {
  background-color: #e3edf7 !important;
}
.text-blue-500 {
  color: #000055 !important;
}
.text-blue-100 {
  color: #3562fd !important;
}
.text-blue-300 {
  color: #00308f !important;
}
.text-black-300 {
  color: #333333 !important;
}

.ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

#defaultModal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

button:disabled {
  opacity: 0.5;
}

.ant-image-preview-wrap {
  z-index: 10001;
  .ant-image-preview-operations {
    background-color: #000;
  }
}

.scroll-to-top {
  bottom: 20px;
  right: 15px;
  div {
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    // box-shadow: rgba(204 204 204, 0.5) 0px 2px 10px 0px;
    box-shadow: rgba(204, 204, 204, 1) 0 2px 8px;
    border: 1px solid rgba(232, 232, 232, 1);
    // border: 1px solid #efefef;
    background-color: white;
    line-height: 0;
    padding: 4px;
    width: 40px;
    height: 40px;

    transition:
      transform 0.1s linear 0s,
      opacity 0.4s linear 0ms,
      visibility 0ms linear 0ms;
  }
}

div.group {
  div {
    position: absolute;
    white-space: nowrap;
    bottom: -30px;
    padding: 10px;
    border-radius: 10px;
    background-color: #000;
    color: white;
    z-index: 200;
  }
}

.status-text-progress {
  color: black !important;
  max-width: 400px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
