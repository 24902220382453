#survey-card-container-v2 {
    .project-container {
        display: flex;
        flex: 1;
        height: 120px;
        padding: 20px;
        background: #fff;
        border-top: 0.5px solid #e5e6ee;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        span,
        label {
            font-size: 18px;
        }
        .project-id-container {
            margin-right: 20px;
        }
        .process-container {
            font-size: 15px;
            svg {
                margin-right: 10px;
                color: #387436;
            }
            span {
                color: #387436;
                font-size: 15px;
                white-space: nowrap;
            }
        }
        .project-timeline-body-bottom {
            zoom: 80%;
            > div {
                flex: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: space-between;
                font-size: 16px;
                .process-step {
                    color: #666666;
                    border-radius: 15px;
                    background-color: #e5e6ee;
                    padding: 5px 10px;
                    margin-right: 5px;
                    white-space: nowrap;
                    margin-top: 10px;
                }
                .process-step.pass {
                    background-color: #b4d0b3;
                }

                .process-step.active {
                    background-color: #387436;
                    color: white;
                }
            }
        }
    }
}
