#survey-detail-page {
    .discuss-container img {
        width: 18px;
        height: 16px;
    }
    .survey-card-container-mobile {
        flex: 1;

        .survey-card {
            // background-color: #fff;
            // padding: 10px;
            display: flex;
            flex-direction: column;
            // background-color: #fff;
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            .row1 {
                align-items: center;
                .project-type {
                    display: flex;
                    svg {
                        height: 18px;
                        width: 21px;
                        color: #0c4da2;
                    }
                    span {
                        margin-left: 5px;
                        color: #0c4da2;
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
                .organize-container img {
                    width: 26px;
                    object-fit: contain;
                    height: 26px;
                    margin-right: 5px;
                }
            }
            .column2 {
                display: flex;
                flex-direction: column;
                flex: 1;
                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 10px;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: left;
                    color: #0c4da2;
                }
                .description {
                    margin-top: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #333;
                }
                .webboard-row {
                    margin-top: 10px;
                    display: flex;
                    font-size: 12px;
                    .lawgroup-text {
                        max-width: 250px;
                        overflow: hidden;
                        display: inline-block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    label {
                        font-size: 12px;
                    }
                    span {
                        font-size: 12px;
                    }
                    .badge-answer {
                        margin-left: 5px;
                        padding: 2px 7.5px;
                        border-radius: 5px;
                        background-color: #488345;
                        color: #fff;
                    }
                    .badge-public {
                        margin-left: 5px;
                        padding: 2px 7.5px;
                        border-radius: 5px;
                        background-color: #0e4ea3;
                        color: #fff;
                    }
                    .expand {
                        align-items: center;
                        padding-left: 7.5px;
                        border-left: 2px solid #e5e6ee;
                        .badge-public {
                            margin-right: 5px;
                            padding: 2px 7.5px;
                            border-radius: 5px;
                            background-color: #0e4ea3;
                            color: #fff;
                        }
                    }
                }
                .progressbar-row {
                    display: flex;
                    flex: 1;
                    align-self: flex-end;
                    justify-content: flex-end;
                    justify-items: flex-end;
                    align-items: flex-end;
                    margin-top: 10px;
                    width: 100%;
                    span {
                        white-space: nowrap;
                    }
                    span,
                    label {
                        color: #666666;
                    }
                }
            }
        }
    }
}
