#survey-card-container-v2 {
    .survey-sub-card-container {
        cursor: pointer;
        display: flex;
        flex: 1;
        height: 120px;
        padding: 20px;
        background: #fff;
        border-top: 0.5px solid #e5e6ee;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        .title {
            font-size: 16px;
        }
        .badge-public {
            margin-left: 5px;
            padding: 2px 7.5px;
            border-radius: 3px;
            font-size: 12px;
            background-color: #6d7481;
            color: #fff;
        }
        .bottom-position {
            color: #6d7481;
            .survey-type-container {
                margin-right: 10px;
                svg {
                    font-size: 14px;
                    margin-right: 5px;
                    height: 16px;
                    width: 16px;
                }
            }
        }
    }
}
