.contact-title {
  object-fit: contain;
  font-family: Kanit-Regular;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.contact-header {
  object-fit: contain;
  font-family: Kanit-Regular;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.07;
  letter-spacing: normal;
  text-align: left;
  color: #0c4da2;
}


.contact .ant-list-item-meta-avatar {
  min-width: 30px;
}
