@media (min-width: 320px) {
  .card-listening {
    // height: 220px;
    padding: 16px 12px 14px;
    margin: 14px 16px 12px 15px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    img.logo {
      width: 23px;
      height: 23px;
      margin: 0 6px 0 0;
    }
    img.content {
      width: 73px;
      height: 60.5px;
      margin: 0 0 15px 1px;
    }
    .text-title a {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      font-family: Kanit-Regular;
      text-decoration-line: underline;
      letter-spacing: normal;
      text-align: left;
      margin: 1px 24px 0px 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
    .text-description {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #697288;
      margin: 13px 37px 12px 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 60px;
    }
    .ant-space-item {
      font-size: 12px;
    }
    .organize-div {
      display: flex;
      align-items: center;
    }
    .organize-container {
      height: 30px;
      margin: 0px 18px 0px 16px;
      padding: 3px 8px 4px 5px;
      object-fit: contain;
      border-radius: 15px;
      box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
        inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
      background-color: #f4f6f9;
      label {
        font-size: 12px;
      }
    }
    .progress-container {
      display: flex;
      margin-top: 10px;
      align-items: center;
      border-top: 1px solid #cccccc;
      padding-top: 10px;
      span {
        font-size: 11px;
      }
      div {
        font-size: 11px;
      }
      .ant-progress {
        margin: 0px 10px;
      }
    }
  }
}
@media (min-width: 768px) {
  .card-listening {
    // height: 280px;
    padding: 30px 30px 24px 29px;
    object-fit: contain;
    border-radius: 10px;
    margin-bottom: 30px;
    box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    img.logo {
      width: 28px;
      height: 28px;
      margin: 0 7px 0 0;
    }
    img.content {
      width: 138px;
      height: 114px;
    }
    .text-title a {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      font-family: Kanit-Regular;
      text-decoration-line: underline;
      letter-spacing: normal;
      text-align: left;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .text-description {
      font-size: 18.5px;
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #697288;
      margin-bottom: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 60px;
    }
    .ant-space-item {
      font-size: 15px;
    }
    .organize-div {
      display: flex;
      align-items: center;
    }
    .organize-container {
      height: 35px;
      margin: 0px 26px 0px 21px;
      padding: 4px 14px 3px 6px;
      object-fit: contain;
      border-radius: 15px;
      box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
        inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
      background-color: #f4f6f9;
      label {
        font-size: 15px;
      }
    }
    .progress-container {
      display: flex;
      flex: 1 1 0%;
      margin-top: 20px;
      align-items: center;
      border-top: 1px solid #cccccc;
      padding-top: 10px;
      span {
        font-size: 16px;
      }
      div {
        font-size: 16px;
      }
      .ant-progress {
        margin: 0px 10px;
        width: 200px !important;
      }
    }
  }
}
