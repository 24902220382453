.Rounded-Rectangle-6-copy-2 {
  width: auto;
  height: fit-content;
  padding: 23px 10px 10px 25px;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15), inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
  background-color: #ffffff;
}

.-copy-2 {
  object-fit: contain;
  font-size: 20px;
  font-style: normal;
  color:  #8e99ad;
}

.-copy-3 {
  object-fit: contain;
  font-size: 13px;
  font-style: normal;
  color: #282828;
}