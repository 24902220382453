// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;
$tablet: '(min-width: 768px) and (max-width: 1023px)';
$desktop: '(min-width: 1024px)';
$iphone6: 'screen and (min-device-width : 375px) and (max-device-width : 667px) and (width : 375px) and (height : 559px) and (orientation : portrait) and (color : 8) and (device-aspect-ratio : 375/667) and (aspect-ratio : 375/559) and (device-pixel-ratio : 2) and (-webkit-min-device-pixel-ratio : 2)';

.btn-backhome {
  font-size: 24px;
  font-weight: bold;
  padding: 10px;
  border: 1px solid #333;
  min-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.btn-meanlaw {
  font-size: 20px;
  text-decoration: underline;
  margin-top: 20px;
  margin-bottom: 30px;
}
