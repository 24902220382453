// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;
$tablet: "(min-width: 768px) and (max-width: 1023px)";
$desktop: "(min-width: 1024px)";
$iphone6: "screen and (min-device-width : 375px) and (max-device-width : 667px) and (width : 375px) and (height : 559px) and (orientation : portrait) and (color : 8) and (device-aspect-ratio : 375/667) and (aspect-ratio : 375/559) and (device-pixel-ratio : 2) and (-webkit-min-device-pixel-ratio : 2)";
@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina":
      "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
    ">maxWidth": "(min-width: #{$mediaMaxWidth + 1})",
    "<maxWidth": "(max-width: #{$mediaMaxWidth})",
    ">bp1Width": "(min-width: #{$mediaBp1Width + 1})",
    "<bp1Width": "(max-width: #{$mediaBp1Width})",
    ">minWidth": "(min-width: #{$mediaMinWidth + 1})",
    "<minWidth": "(max-width: #{$mediaMinWidth})",
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query != "") {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

.section-caroulsel-head {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.section-sub-branner-image-section {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    width: 100%;
    height: 400px;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
    width: 100%;
    height: 400px;
  }
  @include media("screen", "<minWidth") {
    width: 100%;
    height: 200px;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.section-sub-branner-image-section2 {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    width: 100%;
    height: 46px;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
    width: 100%;
    height: 46px;
  }
  @include media("screen", "<minWidth") {
    width: 100%;
    height: 46px;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.background-data {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    width: 100%;
    height: 106px;
    padding: 0 0 15px;
    object-fit: contain;
    background-color: #f1f3f7;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.background-text-in-side {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    object-fit: contain;
    font-family: Kanit;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    padding-top: 55px !important;
    padding-left: 20px;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-in-side-3 {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    margin: auto;
    width: auto;
    border: 8px;
    padding-left: 6%;
    color: #333333;
    object-fit: contain;
    font-size: 18px;
    font-family: Kanit;
    padding-top: 6%;
    font-weight: 500;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-in-side-4 {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    margin: auto;
    width: 100%;
    border: 8px;
    padding-left: 6%;
    object-fit: contain;
    font-size: 16px;
    font-family: Kanit;
    padding-top: 3%;
    font-weight: 500;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.Shape-1 {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    width: 315px;
    height: 1px;
    object-fit: contain;
    background-color: #ced3da;
    margin-left: 7%;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.space-align-container {
  display: flex;
  align-item: flex-start;
  flex-wrap: wrap;
}

.map-marker-alt-duotone {
  width: 21px;
  height: 28px;
  object-fit: contain;
  color: #0c4da2;
}

.map-marker-alt-duotone-p {
  width: auto;
  height: 32px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  padding-left: 4%;
}

.text-in-side-5 {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    margin: auto;
    width: 100%;
    border: 8px;
    padding-left: 6%;
    object-fit: contain;
    font-size: 16px;
    font-family: Kanit;
    padding-top: 2%;
    font-weight: 500;
    padding-bottom: 2%;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.Rounded-Rectangle-4-copy {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    width: 100%;
    height: 65px;
    padding: 0 1px 10px 0;
    object-fit: contain;
    box-shadow: 2.5px 1.7px 8px 0 rgba(12, 77, 162, 0.23),
      5.7px 2px 30px 0 rgba(39, 176, 223, 0.25),
      inset 9.2px 9.2px 26px 0 #ffffff,
      inset -9.2px -9.2px 26px 0 rgba(105, 217, 255, 0.15);
    background-color: #ffffff;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.Rounded-Rectangle-8 {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    width: 380px;
    height: 45px;
    margin: 10px 15px 0 14px;
    padding: 11px 57px 10px 0;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
      -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
      inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
    background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
    border: none;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
  @media only screen and (min-device-width : 414px) 
    and (max-device-width : 736px)
    and (device-width : 414px)
    and (device-height : 736px)
    and (orientation : portrait) 
    and (-webkit-min-device-pixel-ratio : 3) 
    and (-webkit-device-pixel-ratio : 3) {
    width: 385px;
    height: 45px;
    margin: 10px 15px 0 14px;
    padding: 11px 57px 10px 0;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
      -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
      inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
    background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
    border: none;
  }
}

// align-items : flex-start | flex-end | center | baseline | stretch

.flexs-container {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    display: flex;
    flex-wrap: wrap;
    font-size: 30px;
    text-align: center;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.p {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    width: 138px;
    height: 15px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fdfeff;
    padding-top: -50px;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.edit-duotone {
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
    width: 25px;
    height: 21px;
    margin: 0 10px 3px 115px;
    object-fit: contain;
    color: #f8fdff;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}
