// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */
.section {
  width: 100%;
  height: 85px;
  padding: 30px 23px 15px 15px;
  object-fit: contain;
  background-color: #f1f3f7;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 100%;
    height: 85px;
    padding: 30px 23px 15px 15px;
    object-fit: contain;
    background-color: #f1f3f7;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 85px;
    padding: 30px 23px 15px 15px;
    object-fit: contain;
    background-color: #f1f3f7;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 8pc;
    padding: 30px 23px 15px 15px;
    object-fit: contain;
    background-color: #f1f3f7;
  }
}

.section-label {
  padding-left: 14px;
  font-size: 16px;
  object-fit: contain;
  font-family: Kanit;
  color: #333333;
  text-align: left;
  width: 100%;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding-left: 14px;
    font-size: 16px;
    object-fit: contain;
    font-family: Kanit;
    color: #333333;
    text-align: left;
    width: 100%;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding-left: 14px;
    font-size: 16px;
    object-fit: contain;
    font-family: Kanit;
    color: #333333;
    text-align: left;
    width: 100%;
  }
  @include media('screen', '<minWidth') {
    padding-left: 14px;
    font-size: 16px;
    object-fit: contain;
    font-family: Kanit;
    color: #333333;
    text-align: left;
    width: 100%;
  }
}

.container {
  display: flex;
  float: left;
  padding-top: 30px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    display: flex;
    float: left;
    padding-top: 30px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    display: flex;
    float: left;
    padding-top: 30px;
  }
  @include media('screen', '<minWidth') {
    display: flex;
    float: left;
    padding-top: 30px;
  }
}

.section1-container {
  text-align: justify;
  padding-top: 1pc;
  padding-left: 1pc;
  font-size: 16px;
  font-style: italic;
  color: #0c4da2;
  font-weight: 200;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    text-align: justify;
    padding-top: 1pc;
    padding-left: 1pc;
    font-size: 16px;
    font-style: italic;
    color: #0c4da2;
    font-weight: 200;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    text-align: justify;
    padding-top: 1pc;
    padding-left: 1pc;
    font-size: 16px;
    font-style: italic;
    color: #0c4da2;
    font-weight: 200;
  }
  @include media('screen', '<minWidth') {
    text-align: justify;
    padding-top: 1pc;
    padding-left: 1pc;
    font-size: 16px;
    font-style: italic;
    color: #0c4da2;
    font-weight: 200;
  }
}

.section2-container {
  height: 1px;
  object-fit: contain;
  background-color: #ced3da;
  margin-top: 4pc;
  margin-left: 1pc;
  margin-right: 1pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    height: 1px;
    object-fit: contain;
    background-color: #ced3da;
    margin-top: 4pc;
    margin-left: 1pc;
    margin-right: 1pc;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    height: 1px;
    object-fit: contain;
    background-color: #ced3da;
    margin-top: 4pc;
    margin-left: 1pc;
    margin-right: 1pc;
  }
  @include media('screen', '<minWidth') {
    height: 1px;
    object-fit: contain;
    background-color: #ced3da;
    margin-top: 4pc;
    margin-left: 1pc;
    margin-right: 1pc;
  }
}

.Rectangle-3-copy-5 {
  width: fit-content;
  height: 30px;
  padding: 6px 10px 4px;
  object-fit: contain;
  border-radius: 14.9px;
  background-color: #0c4da2;
  color: white;
  font-size: 12px;
  line-height: 1.6;
  margin-top: 0.7pc;
  margin-right: 5px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: fit-content;
    height: 30px;
    padding: 6px 10px 4px;
    object-fit: contain;
    border-radius: 14.9px;
    background-color: #0c4da2;
    color: white;
    font-size: 12px;
    line-height: 1.6;
    margin-top: 0.7pc;
    margin-right: 5px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: fit-content;
    height: 30px;
    padding: 6px 10px 4px;
    object-fit: contain;
    border-radius: 14.9px;
    background-color: #0c4da2;
    color: white;
    font-size: 12px;
    line-height: 1.6;
    margin-top: 0.7pc;
    margin-right: 5px;
  }
  @include media('screen', '<minWidth') {
    width: fit-content;
    height: 30px;
    padding: 6px 10px 4px;
    object-fit: contain;
    border-radius: 14.9px;
    background-color: #0c4da2;
    color: white;
    font-size: 12px;
    line-height: 1.6;
    margin-top: 0.7pc;
    margin-right: 5px;
  }
}

.comment-alt-edit-duotone-2 {
  width: 19px;
  height: 19px;
  margin: 1px 7px 0 0;
  object-fit: contain;
  color: #ffffff;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 19px;
    height: 19px;
    margin: 1px 7px 0 0;
    object-fit: contain;
    color: #ffffff;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 19px;
    height: 19px;
    margin: 1px 7px 0 0;
    object-fit: contain;
    color: #ffffff;
  }
  @include media('screen', '<minWidth') {
    width: 19px;
    height: 19px;
    margin: 1px 7px 0 0;
    object-fit: contain;
    color: #ffffff;
  }
}

.Rounded-Rectangle-6 {
  width: fit-content;
  // height: 30px;
  // padding: 3px 8px 4px 5px;
  padding: 7px 10px 10px 8px !important;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
    inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
  background-color: #f4f6f9;
  line-height: 1.6;
  font-size: 12px;
  font-family: Kanit;
  color: #697288;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: fit-content;
    padding: 7px 10px 10px 8px !important;
    object-fit: contain;
    border-radius: 15px;
    box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
    background-color: #f4f6f9;
    line-height: 1.6;
    font-size: 12px;
    font-family: Kanit;
    color: #697288;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: fit-content;
    padding: 7px 10px 10px 8px !important;
    object-fit: contain;
    border-radius: 15px;
    box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
    background-color: #f4f6f9;

    line-height: 1.6;
    font-size: 12px;
    font-family: Kanit;
    color: #697288;
  }
  @include media('screen', '<minWidth') {
    width: fit-content;
    padding: 7px 10px 10px 8px !important;
    object-fit: contain;
    border-radius: 15px;
    box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
    background-color: #f4f6f9;
    line-height: 1.6;
    font-size: 12px;
    font-family: Kanit;
    color: #697288;
  }
}

.Layer-18 {
  width: 23px;
  height: 23px;
  margin: 0 6px 0 0;
  object-fit: contain;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 23px;
    height: 23px;
    margin: 0 6px 0 0;
    object-fit: contain;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 23px;
    height: 23px;
    margin: 0 6px 0 0;
    object-fit: contain;
  }
  @include media('screen', '<minWidth') {
    width: 23px;
    height: 23px;
    margin: 0 6px 0 0;
    object-fit: contain;
  }
}

.container2 {
  display: flex;
  float: right;
  margin-right: 10px;
  margin-top: 0.5pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    display: flex;
    float: right;
    margin-right: 10px;
    margin-top: 0.5pc;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    display: flex;
    float: right;
    margin-right: 10px;
    margin-top: 0.5pc;
  }
  @include media('screen', '<minWidth') {
    display: flex;
    float: right;
    margin-right: 10px;
    margin-top: 0.5pc;
  }
}

.scroll-container {
  @include media('retina') {
    top: 300;
    left: 2%;
    right: 0%;
    min-width: 1320px;
    height: 18px;
    background: repeating-linear-gradient(
        to right,
        #ddd,
        #ddd 4%,
        transparent 4%,
        transparent 5%
      ),
      repeating-linear-gradient(
        to right,
        #0c50a7,
        #39bfe5 8%,
        transparent 8%,
        transparent 10%
      );
    background-size: 200%, 100%;
    background-repeat: repeat-y;
    object-fit: contain;
    padding-left: 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
      inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    top: 300;
    left: 2%;
    right: 0%;
    max-width: 790px;
    min-width: 970px;
    height: 18px;
    background: repeating-linear-gradient(
        to right,
        #ddd,
        #ddd 4%,
        transparent 4%,
        transparent 5%
      ),
      repeating-linear-gradient(
        to right,
        #0c50a7,
        #39bfe5 8%,
        transparent 8%,
        transparent 10%
      );
    background-size: 200%, 100%;
    background-repeat: repeat-y;
    object-fit: contain;
    padding-left: 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
      inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    top: 300;
    left: 2%;
    right: 0%;
    max-width: 770px;
    min-width: 730px;
    height: 18px;
    background: repeating-linear-gradient(
        to right,
        #ddd,
        #ddd 4%,
        transparent 4%,
        transparent 5%
      ),
      repeating-linear-gradient(
        to right,
        #0c50a7,
        #39bfe5 8%,
        transparent 8%,
        transparent 10%
      );
    background-size: 200%, 100%;
    background-repeat: repeat-y;
    object-fit: contain;
    padding-left: 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
      inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
  }
  @include media('screen', '<minWidth') {
    top: 300;
    left: 2%;
    right: 0%;
    min-width: 370px;
    height: 12px;
    background: repeating-linear-gradient(
        to right,
        #ffffff,
        #ffffff 4%,
        transparent 5%,
        transparent 5%
      ),
      repeating-linear-gradient(
        to right,
        #0c50a7,
        #39bfe5 8%,
        transparent 8%,
        transparent 10%
      );
    background-size: 200%, 100%;
    background-repeat: repeat-y;
    padding-left: 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
      inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
  }
}

// .scroll-container .indicator {
//   height: 100%;
//   background-image: linear-gradient(to top, #0c50a7, #39bfe5);
//   box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
//     inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
//   object-fit: contain;
//   border-radius: 10px;
//   @include media("retina") {
//   }
//   @include media("screen", ">bp1Width", "<maxWidth") {
//     height: 100%;
//     background-image: linear-gradient(to top, #0c50a7, #39bfe5);
//     box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
//       inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
//     object-fit: contain;
//     border-radius: 10px;
//   }
//   @include media("screen", ">minWidth", "<bp1Width") {
//     height: 100%;
//     background-image: linear-gradient(to top, #0c50a7, #39bfe5);
//     box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
//       inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
//     object-fit: contain;
//     border-radius: 10px;
//   }
//   @include media("screen", "<minWidth") {
//     height: 100%;
//     background-image: linear-gradient(to top, #0c50a7, #39bfe5);
//     box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
//       inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
//     object-fit: contain;
//     border-radius: 10px;
//   }
// }

// .container {
//   width: 100%;
//   position: fixed;
//   padding-right: 19px;
//   z-index: 2;
//   background-color: white;
//   @include media("retina") {
//   }
//   @include media("screen", ">bp1Width", "<maxWidth") {
//     width: 100%;
//     position: fixed;
//     padding-right: 19px;
//     z-index: 2;
//     background-color: white;
//   }
//   @include media("screen", ">minWidth", "<bp1Width") {
//     width: 100%;
//     position: fixed;
//     padding-right: 19px;
//     z-index: 2;
//     background-color: white;
//   }
//   @include media("screen", "<minWidth") {
//     width: 100%;
//     position: fixed;
//     padding-right: 19px;
//     background-color: white;
//     z-index: 2;
//   }
// }

// .container2 {
//   width: 100%;
//   position: fixed;
//   padding-right: 19px;
//   z-index: 3;
//   background-color: transparent;
// }

// .item {
//   width: 10%;
//   border: 1px solid grey;
//   background-color: transparent;
//   border-radius: 10px;
//   margin-right: 8px;
//   float: left;
//   display: block;
//   height: 15px;
// }

.step-footer .MuiMobileStepper-root div {
  width: 100% !important;
}

.Rounded-Rectangle-9-copy-4 {
  width: 38px;
  height: 8px;
  object-fit: contain;
  border-radius: 4px;
  background-color: #e4e8f0;
  margin-top: 1pc;
  margin-bottom: 0.6pc;
  margin-right: 0.7pc;
  width: 37px;
  height: 9px;
  font-family: Kanit;
  font-size: 11.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 38px;
    height: 8px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #e4e8f0;
    margin-top: 1pc;
    margin-bottom: 0.6pc;
    margin-right: 0.7pc;
    width: 37px;
    height: 9px;
    font-family: Kanit;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 38px;
    height: 8px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #e4e8f0;
    margin-top: 1pc;
    margin-bottom: 0.6pc;
    margin-right: 0.7pc;
    width: 37px;
    height: 9px;
    font-family: Kanit;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
  }
  @include media('screen', '<minWidth') {
    width: 38px;
    height: 8px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #e4e8f0;
    margin-top: 1pc;
    margin-bottom: 0.6pc;
    margin-right: 0.7pc;
    width: 37px;
    height: 9px;
    font-family: Kanit;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
  }
  .active {
    background-color: ' #4bb6d7';
  }
}

.step-container {
  width: 100%;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 100%;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
  }
}

.stepper1-container {
  display: flex;
  justify-content: space-around;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    display: flex;
    justify-content: space-around;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    display: flex;
    justify-content: space-around;
  }
  @include media('screen', '<minWidth') {
    display: flex;
    justify-content: space-around;
  }
}

.stepper1-section {
  padding-left: 1.3pc;
  padding-right: 1.3pc;
  width: 100% !important;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding-left: 1.3pc;
    width: 100% !important;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding-left: 1.3pc;
    width: 100% !important;
  }
  @include media('screen', '<minWidth') {
    padding-left: 1.3pc;
    width: 100% !important;
  }
  .step-container {
    margin-top: 10px;
    .content-label2 {
      margin-top: 10px;
    }
  }
}

.status-label {
  width: 73px;
  height: 10px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #8f9aae;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 73px;
    height: 10px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8f9aae;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 73px;
    height: 10px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8f9aae;
  }
  @include media('screen', '<minWidth') {
    width: 73px;
    height: 10px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8f9aae;
  }
}

.content-label2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.background-copy-3 {
  width: 100%;
  height: 15px;
  object-fit: contain;
  background-color: #f1f3f7;
  margin-top: 2pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 100%;
    height: 15px;
    object-fit: contain;
    background-color: #f1f3f7;
    margin-top: 2pc;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 15px;
    object-fit: contain;
    background-color: #f1f3f7;
    margin-top: 2pc;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 15px;
    object-fit: contain;
    background-color: #f1f3f7;
    margin-top: 2pc;
  }
}
