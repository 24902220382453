#mean-page {
  padding-bottom: 20px;
  .menu-top {
    margin-bottom: 20px;
    div {
      padding: 5px 20px;
      border-radius: 20px;
      border: solid 2px #eff1f5;
      background-color: #ffffff;
    }
  }
  .main-text-container {
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63;
    letter-spacing: normal;
    text-align: left;
    color: #0c4da2;

    .title {
      font-size: 26px;
      font-weight: bold;
      margin-right: 10px;
    }
    .what {
      font-size: 34px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .list-container {
      margin-top: 10px;
      margin-bottom: 10px;
      border-radius: 15px;
      padding: 10px 20px;
      background: #0c4da2;
      color: white;
      font-weight: bold;
    }
  }
  .progress-container {
    // object-fit: contain;
    border-radius: 49px;
    background-color: #ffffff;
    padding: 20px;
    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    div {
      width: 80px;
      height: 80px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #e7edfd;
      margin-bottom: 10px;
    }
    // height: 100%;
    h4 {
      object-fit: contain;
      font-family: Kanit;
      font-size: 1.8rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #0c4da2;
    }
    label {
      font-size: 17px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.58;
      letter-spacing: normal;
      text-align: center;
      color: #0c4da2;
    }
  }
  .text-container {
    margin-top: 50px;
    h1 {
      font-size: 30px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.79;
      letter-spacing: normal;
      text-align: center;
      color: #0c4da2;
    }
    h3 {
      font-size: 20px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.79;
      letter-spacing: normal;
      text-align: center;
      color: #0c4da2;
      margin-top: 10px;
    }
    label {
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #0c4da2;
      margin-top: 10px;
    }
  }
  .give-container {
    width: 250px;
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 10px;
    > div {
      width: 150px;
      height: 150px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      margin-bottom: 10px;
    }
  }
  .agency-container {
    width: 250px;
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 10px;
    > div {
      width: 150px;
      height: 150px;
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #0c4da2;
      margin-bottom: 10px;
      img {
        border-radius: 60px;
      }
    }
  }
}
