.organize-container {
  height: 30px;
  margin: 0px 18px 0px 16px;
  padding: 10px;
  object-fit: contain;
  border-radius: 15px;
  box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
    inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
  background-color: #f4f6f9;
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    font-size: 12px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
}
