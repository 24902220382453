#about-phase2 {
    .top-container {
        padding: 10px;
        border: 2px solid #eff1f5;
        min-width: 270px;
        border-radius: 5px;
        .count {
            border-radius: 20px;
            padding: 2px 10px;
            background-color: #0e4ea3;
            color: white;
        }
    }
    table {
        width: 100%;
        border: 2px solid #eff1f5;
        padding: 10px;
        tr {
            border: 2px solid #eff1f5;
            th {
                padding: 10px;
                white-space: nowrap;
            }
            td {
                padding: 10px;
            }
        }
        .count-blue {
            border-radius: 20px;
            padding: 2px 10px;
            background-color: #0e4ea3;
            color: white;
        }
        .count-green {
            border-radius: 20px;
            padding: 2px 10px;
            background-color: #b4d0b3;
            color: #438940;
        }
    }
}
