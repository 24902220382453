// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */
.section {
  width: 100%;
  height: 8pc;
  object-fit: contain;
  background-color: #f1f3f7;
  padding-top: 3.6pc;
  font-family: Kanit;
  font-size: 11.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 100%;
    height: 8pc;
    object-fit: contain;
    background-color: #f1f3f7;
    padding-top: 3.6pc;
    font-family: Kanit;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 8pc;
    object-fit: contain;
    background-color: #f1f3f7;
    padding-top: 3.6pc;
    font-family: Kanit;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 8pc;
    object-fit: contain;
    background-color: #f1f3f7;
    padding-top: 3.6pc;
    font-family: Kanit;
    font-size: 11.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.61;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
}

.section-detail-container {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 1pc;
  padding-left: 1pc;
  padding-right: 1pc;
  font-weight: 500;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-top: 1pc;
    padding-left: 1pc;
    padding-right: 1pc;
    font-weight: 500;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-top: 1pc;
    padding-left: 1pc;
    padding-right: 1pc;
    font-weight: 500;
  }
  @include media('screen', '<minWidth') {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-top: 1pc;
    padding-left: 1pc;
    padding-right: 1pc;
    font-weight: 500;
  }
}

.button-radius-principle {
  margin-right: 1pc;
  border-radius: 5px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    margin-right: 1pc;
    border-radius: 5px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    margin-right: 1pc;
    border-radius: 5px;
  }
  @include media('screen', '<minWidth') {
    margin-right: 1pc;
    border-radius: 5px;
  }
}

.comments-alt-duotone-principle {
  width: 22px;
  height: 20px;
  object-fit: contain;
  color: #a7acb3;
}

.icon-text-principle {
  display: flex;
  align-items: baseline;
  font-size: 13px;
  color: #6d7481;
}

.Value {
  width: 66px;
  height: 12px;
  margin: 4px 18px 1px 0;
  object-fit: contain;
  font-family: Kanit;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6d7481;
}

.chevron-circle-down-duotone {
  width: 17px;
  height: 17px;
  margin: 3.8px 0 0 5px;
  object-fit: contain;
  color: #a7acb3;
}

.button-container-principle {
  display: flex;
  justify-content: baseline;
}

.background-copy-4 {
  width: 100%;
  height: 15px;
  object-fit: contain;
  background-color: #f1f3f7;
  margin-top: 1pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 100%;
    height: 15px;
    object-fit: contain;
    background-color: #f1f3f7;
    margin-top: 1pc;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 15px;
    object-fit: contain;
    background-color: #f1f3f7;
    margin-top: 1pc;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 15px;
    object-fit: contain;
    background-color: #f1f3f7;
    margin-top: 1pc;
  }
}

.text-count-comment {
  font-family: Kanit;
  font-size: 14px;
  text-align: left;
  color: #6d7481;
  padding-bottom: 10px;
}

.text-comment-date {
  font-size: 12px;
  color: #6d7481;
}

.comment-date-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .comment-date-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.comment-content {
  background-color: #f0f2f6;
  padding: 16px 16px;
  border-radius: 20px;
  .name-user {
    font-size: 15px;
    color: #333333;
  }
  .comment-date {
    font-size: 12px;
    color: #6d7481;
  }
}

.text-comment {
  object-fit: contain;
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #282828;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    // width: 289px;
    // height: 176px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #282828;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    // width: 289px;
    // height: 176px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #282828;
  }
  @include media('screen', '<minWidth') {
    // width: 289px;
    // height: 176px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #282828;
  }
}

.ant-pagination {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  text-align: center;
  padding-bottom: 5pc;
}

.comment-content-container {
  display: flex;
  flex-direction: row;
  padding: 10px 16px 10px 16px;
}

.under-comment-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-size: 16px;
  // padding: 1pc 1pc 1pc 1pc;
  padding-left: 16px;
  padding-right: 16px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1pc 1pc 1pc 1pc;
    font-size: 16px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1pc 1pc 1pc 1pc;
    font-size: 16px;
  }
  @include media('screen', '<minWidth') {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 1pc 1pc 0pc 1pc;
    font-size: 16px;
  }
}

.comment-alt-edit-duotone-2 {
  width: 21px;
  height: 22px;
  object-fit: contain;
  color: #0c4da2;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 21px;
    height: 22px;
    object-fit: contain;
    color: #0c4da2;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 21px;
    height: 22px;
    object-fit: contain;
    color: #0c4da2;
  }
  @include media('screen', '<minWidth') {
    width: 21px;
    height: 22px;
    object-fit: contain;
    color: #0c4da2;
  }
}

.icon-container-inside-2 {
  display: flex;
  align-items: center;
}

.comment-bottom {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9998;
  background: white;
  padding: 10px;
}

.count-comment-all {
  label {
    color: #6d7481;
    font-size: 14px;
  }
}
