.ui-header {
  color: #0c4da2 !important;
}

.div-ui-padding {
  padding-top: 22px;
}

.hr-style {
  height: 2px;
  border-width: 0;
  color: gray;
  background-color: gray;
}

.Background {
  width: 519px;
  height: 150px;
  margin: 36px 30px 55px 120px;
  padding: 0 0 17px;
  object-fit: contain;
  background-color: #ffffff;
}

.Color-1 {
  background-color: #0c4ca3 !important;
  padding-top: 22px;
}

.Color-1-copy-5 {
  background-color: #7d9fcb;
  padding-top: 22px;
}

.Color-1-copy-2 {
  padding-top: 22px;
  background-color: #387436;
}

.Color-1-copy-3 {
  padding-top: 22px;
  background-color: #89b246;
}

.Color-1-copy-6 {
  padding-top: 22px;
  background-color: #6d7481;
}

.Color-1-copy-9 {
  padding-top: 22px;
  background-color: #8b999a;
}

.Color-1-copy-8 {
  padding-top: 22px;
  background-color: #c8ae7b;
}

.Color-1-copy-88 {
  padding-top: 22px;
  background-color: #dbcc91;
}

.Color-1-copy-10 {
  padding-top: 22px;
  background-color: #333333;
}

.Color-1-copy-100 {
  padding-top: 22px;
  background-color: #666666;
}