#survey-card-container-v2-mobile {
    .project-container {
        display: flex;
        flex: 1;
        height: 150px;
        padding: 10px;
        background: #fff;
        border-top: 0.5px solid #e5e6ee;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        span,
        label {
            font-size: 14px;
        }
        .project-id-container {
            margin-right: 10px;
        }
        .process-container {
            svg {
                font-size: 16px;
                margin-right: 10px;
                color: #387436;
            }
            span {
                color: #387436;
            }
        }
    }
    .project-timeline-body-bottom {
        margin-top: 15px;
        > div {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            // justify-content: space-between;
            font-size: 12px;
            .process-step {
                color: #666666;
                border-radius: 15px;
                background-color: #e5e6ee;
                padding: 5px;
                margin-right: 2px;
                white-space: nowrap;
                margin-top: 10px;
                font-size: 12px;
            }
            .process-step.pass {
                background-color: #b4d0b3;
            }

            .process-step.active {
                background-color: #387436;
                color: white;
            }
        }
    }
}
