// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina":
      "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
    ">maxWidth": "(min-width: #{$mediaMaxWidth + 1})",
    "<maxWidth": "(max-width: #{$mediaMaxWidth})",
    ">bp1Width": "(min-width: #{$mediaBp1Width + 1})",
    "<bp1Width": "(max-width: #{$mediaBp1Width})",
    ">minWidth": "(min-width: #{$mediaMinWidth + 1})",
    "<minWidth": "(max-width: #{$mediaMinWidth})",
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query != "") {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

.toggle_icon_web {
  padding-left: 20px;

  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
  }
}

.toggle_icon_mobile {
  svg {
    width: 24px !important;
    height: 20px !important;
  }
}

.main-label-menu-web {
  padding: 7px 0 0 0;
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
  }
}

.main-label-menu {
  @include media("retina") {
    color: #275394;
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    font-family: Kanit;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.3;
    letter-spacing: normal;
    text-align: left;
    color: #275394;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
  }
}

.comment-main-alt-edit-menu {
  @include media("retina") {
    width: 27px;
    height: 33px;
    margin-right: 30px;
    color: black !important;
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
  }
}

.menu-main-icon-container {
  display: flex;
  justify-content: flex-end;
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
  }
}

.Rounded-Rectangle-8-web {
  width: 260px;
  height: 55px;
  margin: 0 0 3px 30px;
  padding: 18px 39px 15px 40px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3), -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
    inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36), inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
}

.badge-check-duotone {
  width: 15px;
  height: 28px;
  object-fit: contain;
  color: #387436;
  position: relative;
  margin-bottom: 20px;
}

.header-right-menu {
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    margin-right: 40px;
  }
  svg {
    width: 40px;
    height: 40px;
    margin-right: 40px;
  }
}

.text-icon:hover {
  color: #0c4da2;
}
