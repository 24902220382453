$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina":
      "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
    ">maxWidth": "(min-width: #{$mediaMaxWidth + 1})",
    "<maxWidth": "(max-width: #{$mediaMaxWidth})",
    ">bp1Width": "(min-width: #{$mediaBp1Width + 1})",
    "<bp1Width": "(max-width: #{$mediaBp1Width})",
    ">minWidth": "(min-width: #{$mediaMinWidth + 1})",
    "<minWidth": "(max-width: #{$mediaMinWidth})",
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query != "") {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */
.principle-header {
  font-family: Kanit;
  font-size: 12px;
  color: #333333;
  @include media("retina") {
    font-family: Kanit;
    font-size: 12px;
    color: #333333;
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    font-family: Kanit;
    font-size: 12px;
    color: #333333;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
    font-family: Kanit;
    font-size: 12px;
    color: #333333;
  }
  @include media("screen", "<minWidth") {
    font-family: Kanit;
    font-size: 12px;
    color: #333333;
  }
}

.principle-card {
  margin-bottom: 15px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
    inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
  background-color: #ffffff;
  @include media("retina") {
    margin-bottom: 15px;

    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
    background-color: #ffffff;
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    margin-bottom: 15px;

    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
    background-color: #ffffff;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
    margin-bottom: 15px;

    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
    background-color: #ffffff;
  }
  @include media("screen", "<minWidth") {
    margin-bottom: 15px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
    background-color: #ffffff;
  }
}

.principle-icon-card {
  object-fit: contain;
    font-size: 25px;
    font-weight: bold;
    color: #8e99ad;
  @include media("retina") {
   object-fit: contain;
    font-size: 25px;
    font-weight: bold;
    color: #8e99ad;
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    object-fit: contain;
    font-size: 25px;
    font-weight: bold;
    color: #8e99ad;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
   object-fit: contain;
    font-size: 25px;
    font-weight: bold;
    color: #8e99ad;
  }
  @include media("screen", "<minWidth") {
    object-fit: contain;
    font-size: 25px;
    font-weight: bold;
    color: #8e99ad;
  }
}

.principle-card-text {
  padding-left: 15px;
  font-size: 12px;
  text-align: left;
  color: #282828;
  @include media("retina") {
    padding-left: 15px;
    font-size: 12px;
    text-align: left;
    color: #282828;
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    padding-left: 15px;
    font-size: 12px;
    text-align: left;
    color: #282828;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
    padding-left: 15px;
    font-size: 12px;
    text-align: left;
    color: #282828;
  }
  @include media("screen", "<minWidth") {
    padding-left: 15px;
    font-size: 12px;
    text-align: left;
    color: #282828;
  }
}

.images-scroll {
  width: 750px;
  height: 150px;
  overflow: scroll;
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    width: 750px;
    height: 150px;
    overflow: scroll;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
    width: 750px;
    height: 150px;
    overflow: scroll;
  }
  @include media("screen", "<minWidth") {
    width: 350px;
    height: 150px;
    overflow: scroll;
  }
}
