// Predefined Break-points
// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 436px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */
.section-head {
  //   padding: 40px;
  // background: #fff;
  padding: 22px 0px 16px 5px;
  font-size: 20px;
  color: #333333;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    // background: #fff;
    padding: 22px 0px 16px 5px;
    font-size: 20px;
    color: #333333;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    // background: #fff;
    padding: 22px 0px 16px 5px;
    font-size: 20px;
    color: #333333;
  }
  @include media('screen', '<minWidth') {
    // background: #fff;
    padding: 22px 0px 16px 5px;
    font-size: 10px;
    color: #333333;
  }
}

.under-progress {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    color: #5e5d5d;
    font-size: 16px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    color: #5e5d5d;
    font-size: 16px;
  }
  @include media('screen', '<minWidth') {
    color: #5e5d5d;
    font-size: 12px;
  }
}

.under-head-card {
  color: #0c4da2;
  padding-right: 12px;
  font-size: 16px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    color: #0c4da2;
    padding-right: 12px;
    font-size: 16px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    color: #0c4da2;
    padding-right: 12px;
    font-size: 16px;
  }
  @include media('screen', '<minWidth') {
    color: #0c4da2;
    padding-right: 8px;
    font-size: 10px;
  }
}

.Rounded-Rectangle-6 {
  width: fit-content;
  height: 35px;
  padding: 4px 11px 3px 6px;
  object-fit: contain;
  border-radius: 17.5px;
  box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
    inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
  background-color: #f4f6f9;
  color: #697288;
  font-weight: bold;
  font-size: 16px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: fit-content;
    height: 35px;
    padding: 4px 11px 3px 6px;
    object-fit: contain;
    border-radius: 17.5px;
    box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
    background-color: #f4f6f9;
    color: #697288;
    font-weight: bold;
    font-size: 16px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: fit-content;
    height: 35px;
    padding: 4px 11px 3px 6px;
    object-fit: contain;
    border-radius: 17.5px;
    box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
    background-color: #f4f6f9;
    color: #697288;
    font-weight: bold;
    font-size: 16px;
  }
  @include media('screen', '<minWidth') {
    width: fit-content;
    height: 28px;
    padding: 4px 11px 3px 6px;
    object-fit: contain;
    border-radius: 17.5px;
    box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
    background-color: #f4f6f9;
    color: #697288;
    font-weight: bold;
    font-size: 10px;
  }
}

.tag-layer {
  width: 28px;
  height: 28px;
  margin: 0 9px 5px 0;
  object-fit: contain;
  border-radius: 50%;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 28px;
    height: 28px;
    margin: 0 9px 5px 0;
    object-fit: contain;
    border-radius: 50%;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 28px;
    height: 28px;
    margin: 0 9px 5px 0;
    object-fit: contain;
    border-radius: 50%;
  }
  @include media('screen', '<minWidth') {
    width: 18px;
    height: 18px;
    margin: 0 9px 2px 0;
    object-fit: contain;
    border-radius: 50%;
  }
}

.timmer-layer {
  color: #8f9aae;
  font-size: 16px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    color: #8f9aae;
    font-size: 16px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    color: #8f9aae;
    font-size: 16px;
  }
  @include media('screen', '<minWidth') {
    color: #8f9aae;
    font-size: 10px;
  }
}

.in-card-list-text {
  color: #333333;
  padding-left: 25px;
  font-size: 14px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    color: #333333;
    padding-left: 25px;
    font-size: 16px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    color: #333333;
    padding-left: 25px;
    font-size: 16px;
  }
  @include media('screen', '<minWidth') {
    color: #333333;
    padding-left: 25px;
    font-size: 12px;
  }
  @media screen and (max-width: 320px) {
    color: #333333;
    padding-left: 25px;
    font-size: 1px;
  }
}

.list-text-title {
  padding-left: 25px;
  font-size: 16px;
}
@media screen and (max-width: 320px) {
  .list-text-title {
    padding-left: 14px;
    font-size: 12px;
  }
}

.out-card-list-layout {
  border-radius: 10px;
  box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
    inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
  background-color: #ffffff;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    border-radius: 10px;
    box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
    background-color: #ffffff;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    border-radius: 10px;
    box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
    background-color: #ffffff;
  }
  @include media('screen', '<minWidth') {
    border-radius: 10px;
    box-shadow: inset 1.4px 1.4px 16px 0 rgba(96, 134, 188, 0.15),
      inset 1.8px 0.9px 38px 0 rgba(105, 123, 157, 0.05);
    background-color: #ffffff;
  }
}

.button-radius {
  border-radius: 15px;
  height: 55px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
    -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
    inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
    inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
  font-size: 16px;
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
      -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
      inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
    font-size: 16px;
    background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
      -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
      inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
    font-size: 16px;
    background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
  }
  @include media('screen', '<minWidth') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
      -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
      inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
    font-size: 12px;
    background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
  }
  span {
    font-size: 16px;
    font-family: Kanit-Regular;
  }
}

.button-radius-success {
  border-radius: 15px;
  height: 55px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(65, 135, 67, 0.3),
    -6.6px -4.6px 30px 0 rgba(84, 187, 80, 0.25),
    inset 6.9px 1.1px 18px 0 rgba(81, 196, 91, 0.36),
    inset -9.2px -9.2px 26px 0 rgba(39, 107, 41, 0.15);
  font-size: 16px;
  background-color: #387436;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(65, 135, 67, 0.3),
      -6.6px -4.6px 30px 0 rgba(84, 187, 80, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(81, 196, 91, 0.36),
      inset -9.2px -9.2px 26px 0 rgba(39, 107, 41, 0.15);
    font-size: 16px;
    background-color: #387436;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(65, 135, 67, 0.3),
      -6.6px -4.6px 30px 0 rgba(84, 187, 80, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(81, 196, 91, 0.36),
      inset -9.2px -9.2px 26px 0 rgba(39, 107, 41, 0.15);
    font-size: 16px;
    background-color: #387436;
  }
  @include media('screen', '<minWidth') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(65, 135, 67, 0.3),
      -6.6px -4.6px 30px 0 rgba(84, 187, 80, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(81, 196, 91, 0.36),
      inset -9.2px -9.2px 26px 0 rgba(39, 107, 41, 0.15);
    font-size: 12px;
    background-color: #387436;
  }
}

.button-radius-reverse {
  border-radius: 15px;
  height: 55px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
    -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
    inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
    inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05);
  font-size: 16px;
  background-color: #e3d6bd;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
      -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
      inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
      inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05);
    font-size: 16px;
    background-color: #e3d6bd;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
      -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
      inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
      inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05);
    font-size: 16px;
    background-color: #e3d6bd;
  }
  @include media('screen', '<minWidth') {
    border-radius: 15px;
    height: 55px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
      -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
      inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
      inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05);
    font-size: 12px;
    background-color: #e3d6bd;
  }
}

.ant-card-type-inner .ant-card-body {
  padding: 8px 13px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding: 8px 13px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding: 8px 13px;
  }
  @include media('screen', '<minWidth') {
    padding: 8px 13px;
  }
}

.card-icon {
  width: auto;
  height: 25px;
  object-fit: contain;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: auto;
    height: 25px;
    object-fit: contain;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: auto;
    height: 25px;
    object-fit: contain;
  }
  @include media('screen', '<minWidth') {
    width: auto;
    height: 25px;
    object-fit: contain;
  }
}

.status-count {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 2.86;
  letter-spacing: normal;
  text-align: left;
  color: #0c4da2;
  margin-right: 5px;
  font-weight: 400;
}

.survey-container {
  padding: 15px;
  object-fit: contain;
  background-color: #f1f3f7;
  .survey-head {
    object-fit: contain;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #666666;
  }
}

.youtube-listening {
  padding-top: 60px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding-top: 60px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding-top: 60px;
  }
  @include media('screen', '<minWidth') {
    padding-top: 60px;
  }
}

.menu-card-list {
  .in-card-list-text {
    font-size: 15px;
    color: #333;
  }
}

.progress-containerStyles div,
.start-date,
.end-date {
  font-size: 11px;
}

.comments-alt-duotone {
  width: 22px;
  height: 20px;
  // margin: 0 14px 0 0;
  color: #0c4da2;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 22px;
    height: 20px;
    // margin: 0 14px 0 0;
    color: #0c4da2;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 22px;
    height: 20px;
    // margin: 0 14px 0 0;
    color: #0c4da2;
  }
  @include media('screen', '<minWidth') {
    width: 22px;
    height: 20px;
    // margin: 0 14px 0 0;
    color: #0c4da2;
  }
}
