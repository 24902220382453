// Predefined Break-points
// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 436px;

@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina":
      "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
    ">maxWidth": "(min-width: #{$mediaMaxWidth + 1})",
    "<maxWidth": "(max-width: #{$mediaMaxWidth})",
    ">bp1Width": "(min-width: #{$mediaBp1Width + 1})",
    "<bp1Width": "(max-width: #{$mediaBp1Width})",
    ">minWidth": "(min-width: #{$mediaMinWidth + 1})",
    "<minWidth": "(max-width: #{$mediaMinWidth})",
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query != "") {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */
.react-player {
  //   padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    position: absolute;
    top: 0;
    left: 0;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
    position: absolute;
    top: 0;
    left: 0;
  }
  @include media("screen", "<minWidth") {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.player-wrapper {
  //   padding: 40px;
  position: relative;
  padding-top: 56.25%;
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
    position: relative;
    padding-top: 56.25%;
  }
  @include media("screen", ">minWidth", "<bp1Width") {
    position: relative;
    padding-top: 56.25%;
  }
  @include media("screen", "<minWidth") {
    position: relative;
    padding-top: 56.25%;
  }
}
