#notification {
    //block
    .noti-container {
        width: 400px;
        height: auto;
        max-height: 500px;
        overflow: scroll;
        position: relative;
        top: 10px;
        right: 135px;
        background-color: white;
        border-radius: 20px;
        // box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        padding-bottom: 20px;
        overflow: hidden;
    }

    .card {
        display: grid;
        width: 350px;
        height: auto;
        background-color:#eff0fa;
        margin: 0 25px 10px 25px;
        border-radius: 10px;
    }

    .card-open {
        display: grid;
        width: 350px;
        height: auto;
        border-style: solid;
        border-width: 2px;
        border-color: #eff0fa;
        background-color:#ffffff;
        margin: 0 25px 10px 25px;
        border-radius: 10px;
    }

    .card-space {
        padding: 10px 15px;
    }

    //icon
    .icon-active {
        height: max-content;
        border-width: 3px;
        border-bottom-color: #0c4da2;
    }

    .icon-setting {
        margin-right: 5px;
        width: 28px;
        height: 29px;
    }

    .icon-frame-primary {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #0c4da2;
        margin-right: 10px;
    }

    .icon-frame-warning {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #ec9527;
        margin-right: 10px;
    }

    .icon-frame-danger {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #f64253;
        margin-right: 10px;
    }

    .icon-frame-success {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background-color: #418a3e;
        margin-right: 10px;
    }

    .icon-rounded-primary {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        border-color: #6d95c8;
        background-color: #0c4da2;
        margin-right: 0px;
        flex-shrink: 0;
    }

    .icon-rounded-success {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        border-color: #83d67f;
        background-color: #418a3e;
        margin-right: 0px;
        flex-shrink: 0;
    }

    .icon-rounded {
        width: 58px;
        height: 58px;
        border-radius: 50%;
        margin-right: 0px;
        flex-shrink: 0;
    }

    .icon-header-frame-size {
        width: 18px;
        margin-right: 0;
    }

    .icon-body-frame-size {
        width: 35px;
        margin-right: 0;
    }

    //display
    .flex {
        display: flex;
    }

    .flex-col {
        display: flex;
        flex-direction: column;
    }

    .header-card {
        justify-content: space-between;
        align-items: baseline;
        margin: 28px 24px;
    }

    .header-card-first {
        justify-content: space-between;
        align-items: center;
    }

    .card-body {
        margin: 10px 0 5px 0;
    }

    .card-body-space {
        margin: 3px 0;
    }

    .scoll {
        overflow: auto;
    }

    //font
    .header-font {
        font-size: 22px;
        color: #0c4da2;
        font-weight: 900;
    }

    .setting-font {
        font-size: 16px;
        color: gray;
    }

    .card-header-font {
        font-size: 16px;
        font-weight: 700;
        color: black;
    }

    .underline {
        text-decoration: underline;
    }

    .margin-left {
        margin-left: 20px;
    }

    .crop {
        width: 270px;
        overflow: hidden;
        height: 50px;
        line-height: 50px;
    }

    /*-----Mobile-----*/
    .container {
        padding: 70px 20px 80px;
        display: grid;
    }

    .header-flex {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .card-mobile {
        display: grid;
        width: 100%;
        background-color:#eff0fa;
        border-radius: 10px;
        margin-top: 10px;
    }

    .card-mobile-open {
        display: grid;
        width: 100%;
        border-style: solid;
        border-width: 2px;
        border-color: #eff0fa;
        background-color:#ffffff;
        margin-top: 10px;
        border-radius: 10px;
    }

    //font-mobile
    .card-header-font-mobile {
        font-size: 18px;
        font-weight: 700;
        color: black;
    }

    .card-space-mobile {
        padding: 15px 20px;
    }
}