// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;
$tablet: '(min-width: 768px) and (max-width: 1023px)';
$desktop: '(min-width: 1024px)';
$iphone6: 'screen and (min-device-width : 375px) and (max-device-width : 667px) and (width : 375px) and (height : 559px) and (orientation : portrait) and (color : 8) and (device-aspect-ratio : 375/667) and (aspect-ratio : 375/559) and (device-pixel-ratio : 2) and (-webkit-min-device-pixel-ratio : 2)';
@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

.section-caroulsel-head {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.section-sub-branner-image-section {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 100%;
    height: 400px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 400px;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 200px;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.section-sub-branner-image-section2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 100%;
    height: 46px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 46px;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 46px;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.section-sub-branner-section {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding-bottom: 3vh;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding-bottom: 3vh;
  }
  @include media('screen', '<minWidth') {
    padding-bottom: 3vh;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }

  @media only #{$iphone6} {
    padding-top: 20vh;
    padding-bottom: 3vh;
  }
}

.section-sub-branner-image-section-container {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    position: relative;
    text-align: center;
    color: white;
  }
  @include media('screen', '<minWidth') {
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.mean-law-detail-container {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 320px;
    height: 405px;
    padding: 0 0 30px;
    object-fit: contain;
    background-color: #2a2a88;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 405px;
    background-color: #2a2a88;
    position: relative;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 550px;
    background-color: #2a2a88;
    position: relative;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.mean-law-detail-containers {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 320px;
    height: 405px;
    padding: 0 0 30px;
    object-fit: contain;
    background-color: #2a2a88;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 405px;
    background-color: #2a2a88;
    position: relative;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    min-height: 180vh;
    background-color: #2a2a88;
    position: relative;
  }
  @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
    width: 100%;
    min-height: 180vh !important;
    background-color: #2a2a88;
    position: relative;
  }
  @media screen and (device-aspect-ratio: 40/71) {
    width: 100%;
    min-height: 190vh !important;
    background-color: #2a2a88;
    position: relative;
  }

  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.section-sub-branner-image-section-bottom-right {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    position: absolute;
    padding-top: 200px;
    right: 16px;
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px rgba(33, 36, 103, 0.56);
    font-family: Kanit;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
    color: #fdfdfd;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    position: absolute;
    padding-top: 200px;
    right: 16px;
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px rgba(33, 36, 103, 0.56);
    font-family: Kanit;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
    color: #fdfdfd;
  }
  @include media('screen', '<minWidth') {
    position: absolute;
    padding-top: 95px;
    right: 16px;
    text-shadow: 4.9px 4.9px 10px rgba(33, 36, 103, 0.56);
    font-family: Kanit;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: right;
    color: #fdfdfd;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.section-sub-branner-image-section-bottom-right2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    position: absolute;
    padding-top: 250px;
    right: 16px;
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px rgba(33, 36, 103, 0.56);
    font-family: Kanit;
    font-size: 43px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
    color: #fadca3;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    position: absolute;
    padding-top: 250px;
    right: 16px;
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px rgba(33, 36, 103, 0.56);
    font-family: Kanit;
    font-size: 43px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
    color: #fadca3;
  }
  @include media('screen', '<minWidth') {
    position: absolute;
    padding-top: 120px;
    right: 16px;
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px rgba(33, 36, 103, 0.56);
    font-family: Kanit;
    font-size: 36px;
    font-weight: 600;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: right;
    color: #fadca3;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.branner-Layer-42 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 188px;
    mix-blend-mode: overlay;
    z-index: 2;
    position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}
.branner-Layer-41 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: 65px;
    object-fit: contain;
    z-index: 1;
    position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.branner-Layer-41-copy {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: 65px;
    z-index: 1;
    position: absolute;
    right: 0px;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-description1 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    position: absolute;
    width: 100%;
    height: 19px;
    top: 13%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px #161983;
    font-family: Kanit;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.63;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 3;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-description2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    position: absolute;
    width: 100%;
    height: 19px;
    top: 22%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px #161983;
    font-family: Kanit;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.63;
    letter-spacing: normal;
    text-align: center;
    color: #fadca3;
    z-index: 3;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-description3 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    position: absolute;
    width: 350px;
    height: 232px;
    margin: 143px 14px 0 15px;
    top: 30%;
    left: 46%;
    transform: translate(-50%, -50%);
    font-family: Kanit;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 3;
    font-style: italic;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-style-2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    font-size: 22px;
    font-weight: 500;
    font-style: italic;
    color: #fdfdfd;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-style-3 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    color: #6ed0f0;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.mean-law-detail-section-container {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 320px;
    height: 405px;
    padding: 0 0 30px;
    object-fit: contain;
    background-color: #30349b;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 405px;
    background-color: #30349b;
    position: relative;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: fit-content;
    background-color: #30349b;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.mean-law-detail-section-container2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 320px;
    height: 405px;
    padding: 0 0 30px;
    object-fit: contain;
    background-color: #30349b;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 100%;
    height: 405px;
    background-color: #30349b;
    position: relative;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: auto;
    background-color: #30349b;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.mean-law-detail-section-img {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 155px;
    height: 150px;
    margin: 0 58px 13px 69px;
    object-fit: contain;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 155px;
    height: 150px;
    margin: 0 58px 13px 69px;
    object-fit: contain;
  }
  @include media('screen', '<minWidth') {
    width: 155px;
    height: 150px;
    margin: 0 70px 13px 32%;
    object-fit: contain;
    margin-top: 30px !important;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.Rectangle-4-container {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 140px;
    height: 48px;
    margin: 13px 69px 21px 73px;
    padding: 11px 35px 13px;
    object-fit: contain;
    border-radius: 23.6px;
    background-color: #0f1757;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 140px;
    height: 48px;
    margin: 13px 69px 21px 73px;
    padding: 11px 35px 13px;
    object-fit: contain;
    border-radius: 23.6px;
    background-color: #0f1757;
  }
  @include media('screen', '<minWidth') {
    width: 140px;
    height: 48px;
    margin: 13px 69px 21px 73px;
    padding: 11px 35px 13px;
    object-fit: contain;
    border-radius: 23.6px;
    background-color: #0f1757;
    margin-left: 34%;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-section-in-retangle {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 74px;
    height: 0px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding-left: 4.3px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 74px;
    height: 0px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    padding-left: 4.3px;
  }
  @include media('screen', '<minWidth') {
    width: 74px;
    height: 0px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 17.8px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding-left: 5.2px;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-section-out-retangle {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 282px;
    height: 66px;
    margin: 21px 0 0;
    object-fit: contain;
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 282px;
    height: 66px;
    margin: 21px 0 0;
    object-fit: contain;
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 66px;
    margin: 21px 0 0;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 150px !important;
    object-fit: contain;
    font-family: Kanit;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-section-out-retangl2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 282px;
    height: 66px;
    margin: 21px 0 0;
    object-fit: contain;
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 282px;
    height: 66px;
    margin: 21px 0 0;
    object-fit: contain;
    font-family: Kanit;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 66px;
    margin: 21px 0 0;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px !important;
    object-fit: contain;
    font-family: Kanit;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;

    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.mean-section-header {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: 100%;
    height: 25px;
    margin: 0 10px 11px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    padding-top: 30px;

    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.mean-section-label {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: fit-content;
    height: 21px;
    margin: 11px 18px 82px 18px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #fadca3;
    // padding-bottom: 120px;
    padding-top: 30px;

    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.mean-section-label2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: fit-content;
    height: 42px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.img-Layer-13 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: 210px;
    height: 207px;
    margin: 70px 51px -25px 22%;
    object-fit: contain;
    z-index: 1;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.img-Layer-lable-13 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: 300;
    height: 50px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;

    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.img-Layer-lable-5 {
  @include media('retina') {
    width: 300;
    height: 50px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 180px;
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 300;
    height: 50px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 300;
    height: 50px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 180px;
  }
  @include media('screen', '<minWidth') {
    width: 300;
    height: 50px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 180px;

    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // position: absolute;
  }
  @media #{$iphone6} {
    width: fit-content;
    height: 60px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 2;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 180px;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.Layer-27 {
  width: 124px;
  height: 124px;
  object-fit: contain;
}

.text-description27 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    position: absolute;
    width: 350px;
    height: 232px;
    margin: 143px 14px 0 15px;
    top: 13%;
    left: 46%;
    transform: translate(-50%, -50%);
    font-family: Kanit;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 3;
    font-style: italic;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-pic {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: 130px;
    // height: 30px;
    margin: 12px 23px 15px 27px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-pic-copy {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    width: 50%;
    height: 30px;
    margin: 12px 23px 15px 30%;
    object-fit: contain;
    font-family: Kanit;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-left: 25%;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-descriptions1 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    position: absolute;
    width: 100%;
    height: 19px;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px #161983;
    font-family: Kanit;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.63;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    z-index: 3;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}

.text-descriptions2 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
    position: absolute;
    width: 100%;
    height: 19px;
    top: 9%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: contain;
    text-shadow: 4.9px 4.9px 10px #161983;
    font-family: Kanit;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.63;
    letter-spacing: normal;
    text-align: center;
    color: #fadca3;
    z-index: 3;
  }
  @media #{$tablet} {
  }

  @media #{$desktop} {
  }
}
