#detail-result {
    .date-text {
        color: black !important;
    }
    .progressbar-row {
        .status-text-progress {
            color: black !important;
        }
    }
    label.title {
        font-family: Kanit;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.94;
        letter-spacing: normal;
        text-align: left;
        color: #005;
    }
    .search-component {
        margin-top: 1rem;
        .type-search div:not(div.basic-single):not(div) {
            padding: 10px 20px;
            border: 1px solid #efefef;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #e5e6ee;
            color: #0c4da2;
            white-space: nowrap;
            height: fit-content;
        }
        .type-search div.active {
            padding: 10px 20px;
            border: 1px solid #efefef;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #0c4da2;
            color: #fff;
            white-space: nowrap;
            height: fit-content;
        }
        .ant-input-affix-wrapper {
            font-size: 18px;
            border-radius: 20px;
            padding: 2px 2px 2px 20px;
            .search-button {
                background-color: #0c4da2;
                padding: 10px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    color: #fff;
                }
            }
        }
        .button {
            background-color: white;
            color: #3562fd;
            padding: 10px 20px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            cursor: pointer;
        }
        .button.active {
            background-color: #0e4ea3;
            color: white;
            padding: 10px 20px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            cursor: pointer;
        }
        .button-item {
            padding: 5px 10px;
            background-color: #fff;
            color: #0e4ea3;
            border-radius: 10px;
            font-size: 12px;
            border: 1px solid #ababab;
            height: fit-content;
        }
        .button-item.active {
            background-color: #0e4ea3;
            color: #fff;
        }
    }
    .items-container {
        background-color: white;
        .ant-select-selector {
            border-radius: 20px;
            font-size: 16px;
            padding: 10px 20px;
        }
    }
    .left {
        width: 100%;
        border-radius: 5px;
        border: solid 1px #eff1f5;
        padding: 10px 20px;
    }
    .left-container {
        button,
        input,
        select,
        textarea,
        a,
        p,
        label,
        span,
        td,
        tr,
        pre,
        div {
            font-family: BaiJamjuree !important;
        }

        h1,
        h2,
        h3 {
            font-family: BaiJamjuree-SemiBold;
        }

        h4,
        h5 {
            font-family: BaiJamjuree-Bold;
        }
        .left-section-2 {
            .button {
                background-color: #e5e6ee;
                color: #0e4ea3;
                padding: 5px 10px;
                // min-width: 150px;
                white-space: nowrap;
                border: 1px solid #e5e6ee;
                border-radius: 20px;
                cursor: pointer;
                font-size: 14px;
            }
            .button.active {
                background-color: #0e4ea3;
                color: white;
                padding: 5px 10px;
                // min-width: 150px;
                white-space: nowrap;
                border: 1px solid #e5e6ee;
                border-radius: 20px;
                cursor: pointer;
                font-size: 14px;
            }
            .history-container {
                background-color: #e3ecf9;
                border-radius: 5px;
                padding: 10px 20px;
                .border-bottom {
                    border-bottom: 1px solid white;
                }
                .revision {
                    span.text-left {
                        color: #6e809e;
                    }
                }
            }
        }
        .law-master,
        .law-relate {
            border: solid 1px #eff1f5;
            border-radius: 5px;
            div.item {
                padding: 10px 20px;
            }
            .border-bottom {
                border-bottom: 1px solid #efefef;
            }
        }
    }
    .right-container {
        button,
        input,
        select,
        textarea,
        a,
        p,
        label,
        span,
        td,
        tr,
        pre,
        div {
            font-family: BaiJamjuree !important;
        }

        h1,
        h2,
        h3 {
            font-family: BaiJamjuree-SemiBold;
        }

        h4,
        h5 {
            font-family: BaiJamjuree-Bold;
        }
        .tab-container {
            border-radius: 20px;
            border: solid 1px #eff1f5;
            div.group {
                // padding: 5px 15px;
                justify-content: center;
                align-items: center;
                display: flex;
                div {
                    position: absolute;
                    white-space: nowrap;
                    bottom: -30px;
                    padding: 10px;
                    border-radius: 10px;
                    background-color: #000;
                    color: white;
                    z-index: 200;
                }
                button {
                    color: #3562fd;
                    cursor: pointer;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                }
                button:disabled {
                    opacity: 0.3;
                }
            }
            div.group.active {
                // padding: 5px 5px;
                justify-content: center;
                align-items: center;
                display: flex;
                border-right: solid 1px #eff1f5;
                background-color: #0c4da2;
                color: white;
            }
            div.group.last {
                border-right: hidden;
            }
            div.group.disabled {
            }
        }
        .timeline-container {
            .timeline-item {
                background-color: hsl(226, 100%, 94%);
                color: #3562fd;
                border-radius: 5px;
                padding: 5px 10px;
                white-space: nowrap;
            }
            .timeline-item.active {
                background-color: #3562fd;
                color: white;
            }
            .timeline-item.active-border {
                border: #3562fd 1px solid;
            }
            .border {
                border: none;
                border-top: 1px solid #e1e8ff;
                width: 20px;
                height: 1px;
            }
        }
        .tab1 {
            .title-container {
                background-color: #e1e8ff;
                padding: 10px 20px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .content-container {
                padding: 10px 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                border: 1px solid #eff1f5;
                font-family: BaiJamjuree !important;

                p,
                span {
                    font-size: 14px !important;
                }
            }
        }
        .tab3 {
            .title-container {
                background-color: #e1e8ff;
                padding: 10px 20px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .content-container {
                padding: 10px 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                border: 1px solid #eff1f5;
                font-family: BaiJamjuree !important;
            }
            .description-container {
                background-color: #f0f2f6;
                border-radius: 5px;
                border: 1px solid #eff1f5;
                padding: 10px 20px;
                li {
                    margin-left: 20px;
                    font-size: 14px;
                }
                li::marker {
                    padding-left: 20px;
                    content: "• ";
                    font-size: 1.3em;
                }
            }
        }
        .tab4 {
            .title-container {
                background-color: #e1e8ff;
                padding: 10px 20px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            .content-container {
                padding: 20px 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                border: 1px solid #eff1f5;
                font-family: BaiJamjuree !important;
            }
            .description-container {
                background-color: #f0f2f6;
                border-radius: 5px;
                border: 1px solid #eff1f5;
                padding: 10px 20px;
                li {
                    margin-left: 20px;
                    font-size: 14px;
                }
                li::marker {
                    padding-left: 20px;
                    content: "• ";
                    font-size: 1.3em;
                }
            }
        }
    }
    ul.sarabun {
        // height: 200px;
        // overflow-y: scroll;
        direction: rtl;
        li {
            padding-left: 20px;
            margin-top: 10px;
            font-size: 16px;
            color: #333;
            text-align: left;
        }
        li.level-2 {
            padding-left: 30px;
        }
    }
    .text-gray {
        color: #6a768a;
    }
    .filter-survey {
        div {
            padding: 10px 20px;
            border-radius: 20px;
            border: 1px solid #efefef;
            background-color: #e5e6ee;
        }
        div.active {
            padding: 10px 20px;
            border-radius: 20px;
            border: 1px solid #efefef;
            background-color: #fff;
        }
    }
    .survey-item-container {
        border-radius: 14px;
        box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
        border: solid 2px #eff1f5;
        background-color: #fff;
        .title-container {
            padding: 10px 20px;
            border-bottom: 1px solid #efefef;
            .title-name {
                font-weight: bold;
            }
        }
        .content-container {
            padding: 10px 20px;
        }
    }
    .search-advance-container {
        .ant-input {
            background-color: #e5e6ee;
            font-size: 16px;
            padding: 10px 20px;
            border-radius: 10px;
        }
        .button-item {
            padding: 10px 15px;
            background-color: #fff;
            color: #0e4ea3;
            border-radius: 10px;
            box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
        }
        .type-search div {
            padding: 10px 20px;
            border: 1px solid #efefef;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #e5e6ee;
            color: #0c4da2;
            white-space: nowrap;
            height: fit-content;
        }
        .type-search div.active {
            padding: 10px 20px;
            border: 1px solid #efefef;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #0c4da2;
            color: #fff;
            white-space: nowrap;
            height: fit-content;
        }
        .button {
            background-color: white;
            color: #3562fd;
            padding: 10px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .button.active {
            background-color: #0e4ea3;
            color: white;
            padding: 10px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .button-close {
            background-color: #0e4ea3;
            color: white;
            padding: 5px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            cursor: pointer;
            font-size: 16px;
        }
    }
    .search-char-container {
        .type {
            padding: 20px 20px;
            background-color: #e5e6ee;
            border-right: 1px solid #efefef;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            color: #0c4da2;
        }
        .type.left {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .type.right {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .type.active {
            color: white;
            background-color: #0c4da2;
        }
        .char {
            padding: 5px 15px;
            background-color: white;
            box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
            color: #0c4da2;
            font-size: 20px;
            border-radius: 10px;
            cursor: pointer;
        }
        .char.active {
            background-color: #0c4da2;
            color: white;
        }
        .button {
            background-color: white;
            color: #3562fd;
            padding: 10px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .button.active {
            background-color: #0e4ea3;
            color: white;
            padding: 10px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .button-close {
            background-color: #0e4ea3;
            color: white;
            padding: 5px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            cursor: pointer;
            font-size: 16px;
        }
    }
    .filter-container {
        object-fit: contain;
        border-radius: 14px;
        border: solid 2px #eff1f5;
        background-color: #fff;
        padding: 20px;
    }
    .modal-share {
        .copy-button {
            background-color: #e1e8ff;
            color: #3562fd;
            padding: 10px;
            cursor: pointer;
            span {
                white-space: nowrap;
            }
        }
    }
    .ck-content {
        font-family: BaiJamjuree !important;
    }
    .law-content-container.active {
        color: white;
        background-color: #3562fd;
        padding-left: 10px;
        padding-right: 10px;
        .text-left {
            color: white !important;
        }
    }
    .law-content-container.active-border {
        // color: white;
        border: #3562fd 1px solid !important;
        padding-left: 10px;
        padding-right: 10px;
    }
}
