// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

.section-head-details {
  //   padding: 40px;
  padding: 22px 0px 16px 5px;
  font-size: 20px;
  color: #333333;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding: 22px 0px 16px 5px;
    font-size: 20px;
    color: #333333;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding: 22px 0px 16px 5px;
    font-size: 20px;
    color: #333333;
  }
  @include media('screen', '<minWidth') {
    padding: 22px 0px 16px 5px;
    font-size: 12px;
    color: #333333;
  }
}

.section-head-timer-details {
  //   padding: 40px;
  padding: 6px 0px 0px 0px;
  font-size: 20px;
  color: #387436;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding: 6px 0px 0px 0px;
    font-size: 20px;
    color: #387436;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding: 6px 0px 0px 0px;
    font-size: 20px;
    color: #387436;
  }
  @include media('screen', '<minWidth') {
    padding: 6px 0px 0px 0px;
    font-size: 12px;
    color: #387436;
  }
}

.section-head-text-details {
  //   padding: 40px;
  padding: 20px 10px 0px 0px;
  font-size: 24px;
  color: #333333;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding: 20px 0px 0px 0px;
    font-size: 24px;
    color: #333333;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding: 20px 0px 0px 0px;
    font-size: 24px;
    color: #333333;
  }
  @include media('screen', '<minWidth') {
    padding: 20px 0px 0px 0px;
    font-size: 18px;
    color: #333333;
  }
}

.section-head-description-details {
  //   padding: 40px;
  padding: 0px 10px 0px 0px;
  font-size: 16px;
  color: #777777;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    padding: 0px 0px 0px 0px;
    font-size: 16px;
    color: #777777;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    padding: 0px 0px 0px 0px;
    font-size: 16px;
    color: #777777;
  }
  @include media('screen', '<minWidth') {
    padding: 0px 0px 0px 0px;
    font-size: 12px;
    color: #777777;
  }
}

.triangle-details {
  //   padding: 40px;
  width: 69px;
  height: 67px;
  margin: 0 35px 114px 1px;
  padding: 24px 21px 23px 19px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: inset 1.8px 0.9px 20px 0 rgba(137, 148, 169, 0.23);
  background-color: #ffffff;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 69px;
    height: 67px;
    margin: 0 35px 114px 1px;
    padding: 24px 21px 23px 19px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset 1.8px 0.9px 20px 0 rgba(137, 148, 169, 0.23);
    background-color: #ffffff;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 69px;
    height: 67px;
    margin: 0 35px 114px 1px;
    padding: 24px 21px 23px 19px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset 1.8px 0.9px 20px 0 rgba(137, 148, 169, 0.23);
    background-color: #ffffff;
  }
  @include media('screen', '<minWidth') {
    width: 69px;
    height: 67px;
    margin: 0 10px 114px 1px;
    padding: 24px 21px 23px 19px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset 1.8px 0.9px 20px 0 rgba(137, 148, 169, 0.23);
    background-color: #ffffff;
  }
}

.number {
  //   padding: 40px;
  width: 29px;
  height: 20px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: left;
  color: #6c6b6b;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 29px;
    height: 20px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #6c6b6b;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 29px;
    height: 20px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #6c6b6b;
  }
  @include media('screen', '<minWidth') {
    width: fit-content;
    height: fit-content;
    object-fit: contain;
    font-family: Kanit;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    text-align: left;
    color: #6c6b6b;
  }
}

.comment-text {
  //   padding: 40px;
  font-family: Kanit;
  font-size: 24px;
  color: #333333;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    font-family: Kanit;
    font-size: 20px;
    color: #333333;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    font-family: Kanit;
    font-size: 20px;
    color: #333333;
  }
  @include media('screen', '<minWidth') {
    font-family: Kanit;
    font-weight: bold;
    font-size: 15px;
    color: #333333;
  }
}

.switch-text {
  //   padding: 40px;
  font-family: Kanit;
  font-size: 24px;
  padding-left: 10px;
  color: #333333;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    font-family: Kanit;
    font-size: 20px;
    padding-left: 10px;
    color: #333333;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    font-family: Kanit;
    font-size: 20px;
    padding-left: 10px;
    color: #333333;
  }
  @include media('screen', '<minWidth') {
    font-family: Kanit;
    font-size: 16px;
    padding-left: 10px;
    color: #333333;
  }
}

.card-layout {
  margin: 0 1px 0 0;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 4.9px 4.9px 10px 0 rgba(137, 148, 169, 0.4),
    inset 9.2px 9.2px 26px 0 #ffffff,
    inset -9.2px -9.2px 26px 0 rgba(188, 194, 211, 0.44);
  background-color: #ffffff;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    margin: 0 1px 0 0;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 4.9px 4.9px 10px 0 rgba(137, 148, 169, 0.4),
      inset 9.2px 9.2px 26px 0 #ffffff,
      inset -9.2px -9.2px 26px 0 rgba(188, 194, 211, 0.44);
    background-color: #ffffff;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    margin: 0 1px 0 0;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 4.9px 4.9px 10px 0 rgba(137, 148, 169, 0.4),
      inset 9.2px 9.2px 26px 0 #ffffff,
      inset -9.2px -9.2px 26px 0 rgba(188, 194, 211, 0.44);
    background-color: #ffffff;
  }
  @include media('screen', '<minWidth') {
    margin: 0 1px 0 0;
    border-radius: 10px;
    box-shadow: 4.9px 4.9px 10px 0 rgba(137, 148, 169, 0.4),
      inset 9.2px 9.2px 26px 0 #ffffff,
      inset -9.2px -9.2px 26px 0 rgba(188, 194, 211, 0.44);
    background-color: #ffffff;
  }
}

.switch-style {
  margin-left: 8px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    margin-left: 8px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    margin-left: 8px;
  }
  @include media('screen', '<minWidth') {
    margin-left: 8px;
  }
}

.question-page .ant-card-body {
  padding: 10px 20px;
}

.editor-pen {
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ebebeb;
  img {
    margin-right: 10px;
    width: 14px;
  }
  span {
    font-size: 16px;
  }
}

.header-page {
  font-family: Kanit-Regular;
  font-size: 19px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #245aa3;
}

.sent-question-button {
  height: 45px;
  padding: 12px 78px 14px;
  object-fit: contain;
  border-radius: 10px;
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
  span {
    font-size: 16px;
  }
}

.success-question-text {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  // line-height: 2.44;
  letter-spacing: normal;
  text-align: center;
  color: #0c4ea4;
}

.copylink-button {
  margin: 9px 15px 0;
  padding: 10px 31px 9px 12px;
  object-fit: contain;
  border-radius: 5px;
  border: solid 1px #dbdcde;
  background-color: #fefefe;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  span {
    font-size: 11px;
  }
}

// .feedback-button {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 17px 20px 15px;
//   object-fit: contain;
//   border-radius: 10px;
//   box-shadow: 4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
//     -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
//     inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
//     inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05);
//   background-color: #e3d6bd;
//   span {
//     font-family: Kanit-Regular;
//     font-size: 16px;
//     color: #847049;
//   }
// }

.bottom-send-button-container {
  width: 100%;
  padding: 19px 15px 22px;
  position: fixed;
  bottom: 40px;
  background-color: #fff;
  z-index: 998;
}
