#post-page {
  .ant-pagination {
    padding-bottom: 0pc;
  }
  padding-bottom: 20px;
  .menu-top {
    margin-bottom: 20px;
    div {
      padding: 5px 20px;
      border-radius: 20px;
      border: solid 2px #eff1f5;
      background-color: #ffffff;
    }
  }
  .post-project-card {
    border-radius: 10px;
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    padding: 20px 20px;
    .project-id {
      label {
        color: #333333;
        font-size: 14px;
      }
    }
    .announcement-card {
      border-top: 1px solid #eff1f5;
      margin-top: 20px;
      padding-top: 20px;
      .title {
        color: #333;
        font-size: 16px;
        font-weight: bold;
      }
      .content {
        padding: 20px;
        object-fit: contain;
        border-radius: 14px;
        background-color: #eff1f5;
      }
    }
  }
  .agency-card {
    margin-top: 20px;
    .card-title {
      align-items: center;
      span {
        color: #c8ae7b;
        font-weight: bold;
      }
    }
    .card-post {
      padding: 20px;
      object-fit: contain;
      border-radius: 10px;
      border: solid 2px #eff1f5;
      background-color: #e4e3e1;
      margin-top: 15px;
    }
  }
  .public-card {
    margin-top: 20px;
    .card-title {
      align-items: center;
      span {
        // color: #c8ae7b;
        font-weight: bold;
      }
    }
    .card-post {
      padding: 20px;
      object-fit: contain;
      border-radius: 10px;
      margin-top: 15px;
      border: solid 2px #eff1f5;
      background-color: #ffffff;
    }
  }
  .post-public-card {
    padding: 20px;
    object-fit: contain;
    border-radius: 10px;
    margin-top: 15px;
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    .post-user-name {
      align-items: center;
      > div {
        min-width: 70px;
      }
      label {
        letter-spacing: normal;
        text-align: left;
        color: #333333;
        font-size: 14px;

        font-weight: bold;
      }
    }
    .post-content {
      margin-top: 10px;
      span {
        font-size: 14px;
      }
    }
    .post-content-close {
      background-color: #fff2bb;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .post-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
    .post-more-menu {
      border-top: 1px solid #eff1f5;
      border-bottom: 1px solid #eff1f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      .more-menu {
        position: absolute;
        background: rgb(255, 255, 255);
        min-width: 100px;
        top: 20px;
        right: 0px;
        display: none;
        border-radius: 5px;
        box-shadow: 10.6px 10.6px 27px 0 rgba(162, 174, 200, 0.38);
        border: solid 1px #dbdcde;
        background-color: #fefefe;
        div {
          border-bottom: 1px solid #cde3da;
          align-items: center;
          padding: 8px 20px;
          color: #4d5159;
          display: flex;
          svg {
            height: 16px;
            width: 16px;
            margin-right: 10px;
          }
        }
      }
      .more-menu.active {
        display: initial;
      }
    }
  }
  .post-comment-public-card {
    .post-comment-content {
      padding: 10px;
      object-fit: contain;
      border-radius: 15px;
      background-color: #f0f2f6;
      margin-bottom: 20px;
    }
  }
  .post-agency-card {
    padding: 20px;
    object-fit: contain;
    border-radius: 10px;
    margin-top: 15px;
    border: solid 2px #eff1f5;
    background-color: #e4e3e1;
    .post-user-name {
      align-items: center;
      > div {
        min-width: 70px;
      }
      label {
        letter-spacing: normal;
        text-align: left;
        color: #333333;
        font-size: 14px;

        font-weight: bold;
      }
    }
    .post-content {
      margin-top: 10px;
      span {
        font-size: 14px;
      }
    }
    .post-content-close {
      background-color: #fff2bb;
      padding: 20px;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }
    .post-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
    .post-more-menu {
      border-top: 1px solid #eff1f5;
      border-bottom: 1px solid #eff1f5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 10px;
      .more-menu {
        position: absolute;
        background: rgb(255, 255, 255);
        min-width: 100px;
        top: 20px;
        right: 0px;
        display: none;
        border-radius: 5px;
        box-shadow: 10.6px 10.6px 27px 0 rgba(162, 174, 200, 0.38);
        border: solid 1px #dbdcde;
        background-color: #fefefe;
        div {
          border-bottom: 1px solid #cde3da;
          align-items: center;
          padding: 8px 20px;
          color: #4d5159;
          display: flex;
          svg {
            height: 16px;
            width: 16px;
            margin-right: 10px;
          }
        }
      }
      .more-menu.active {
        display: initial;
      }
    }
  }
}
