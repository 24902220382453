// Small devices (landscape phones, 576px and up)
@media (min-width: 320px) {
  .card-agency-container {
    margin: 75px 0px 10px 0px;
    overflow-x: auto;
    padding: 10px 20px;
    .card-agency {
      height: 36px;
      margin: 5px 5px 0 5px;
      padding: 3px 18px 3px 4px;
      object-fit: contain;
      border-radius: 10px;
      box-shadow: 3.9px 1px 10px 0 rgba(137, 148, 169, 0.4);
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 5px;
        width: 24px;
        height: 24px;
        vertical-align: middle;
        background-color: #ffffff;
        border-radius: 24px;
      }
      label {
        font-size: 13px;
        font-family: Kanit-Regular;
      }
    }
  }

  .card-more-container {
    margin: 0px 3px 0px 4px;
  }

  .card-more {
    align-items: center;
    // width: 669px;
    display: flex;
    height: 85px;
    padding: 16px 13px 10px 12px;
    object-fit: contain;
    border-radius: 42.5px;
    background-color: #eff1f5;
    .title {
      font-family: Kanit-Bold;
      // font-weight: bold;
      line-height: 1.11;
      font-style: italic;
      font-size: 18px;
      color: #0c4da2;
      margin-bottom: 0.5em;
      margin-right: 0.5em;
    }
    .description {
      display: none;
    }
  }

  .box-text-more {
    display: flex;
    width: 59px;
    height: 59px;
    padding: 13px 8px 16px 10px;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-color: #0c4da2;
    border-radius: 42.5px;
    span {
      object-fit: contain;
      // font-family: Kanit;
      // font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      // line-height: 3.5;
      text-align: center;
      color: #ffffff;
      font-family: Kanit;
      font-size: 12px;
      letter-spacing: normal;
    }
  }

  .card-carousel {
    height: 150px;
    color: #fff;
    margin: 0px 11px 11px 15px;
    padding: 0.2px 0.3px 0.3px 0;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    // background-color: #ffbbbb;
  }
  .ant-tabs-tab {
    margin: 0 10px 0 0;
  }
  .ant-tabs-tab-btn span {
    margin-right: 5px;
    svg {
      font-size: 14px;
    }
  }
  .ant-tabs-tab label {
    font-family: Kanit-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
  .ant-tabs-nav-wrap {
    padding: 0 10px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .card-agency-container {
    margin: 75px 0px 10px 0px;
    overflow-x: auto;
    padding: 10px 40px;
    .card-agency {
      height: 50px;
      margin: 10px 10px 0 15px;
      padding: 3px 18px 3px 4px;
      object-fit: contain;
      border-radius: 10px;
      box-shadow: 3.9px 1px 10px 0 rgba(137, 148, 169, 0.4);
      background-color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-right: 5px;
        height: 41px;
        width: 41px;
        vertical-align: middle;
        background-color: #ffffff;
        border-radius: 41px;
      }
      label {
        font-size: 18.5px;
        font-family: Kanit-Regular;
      }
    }
  }
  .card-more-container {
    margin: 0px 41px 10px 40px;
  }
  .card-more {
    align-items: center;
    // width: 669px;
    display: flex;
    height: 137px;
    padding: 18px 26px 22px 26px;
    object-fit: contain;
    border-radius: 68.5px;
    background-color: #eff1f5;
    .title {
      font-family: Kanit-Bold;
      // font-weight: bold;
      font-style: italic;
      font-size: 22px;
      color: #0c4da2;
      margin-bottom: 0.5em;
      margin-right: 0.5em;
    }
    .description {
      font-family: Kanit;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #666666;
      display: initial;
    }
  }
  .box-text-more {
    display: flex;
    width: 97px;
    height: 97px;
    padding: 28px 20px 28px 21px;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-color: #0c4da2;
    border-radius: 50px;
    span {
      object-fit: contain;
      // font-family: Kanit;
      // font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      // line-height: 3.5;
      text-align: center;
      color: #ffffff;
      font-family: Kanit;
      font-size: 16px;
      letter-spacing: normal;
    }
  }
  .card-carousel {
    height: 300px;
    color: #fff;
    margin: 10px 25px 20px 40px;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 20px;
    // background-color: #ffbbbb;
  }
  .ant-tabs-tab {
    margin: 0 32px 0 0;
  }
  .ant-tabs-tab-btn span {
    margin: 0;
    svg {
      font-size: 22px;
    }
  }
  .ant-tabs-tab label {
    font-family: Kanit-Regular;
    font-size: 22px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
  .ant-tabs-nav-wrap {
    padding: 0 20px;
  }
}

// // Large devices (desktops, 992px and up)
// @media (min-width: 992px) {  }

// // Extra large devices (large desktops, 1200px and up)
// @media (min-width: 1200px) {  }
