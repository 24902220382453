#survey-detail-page {
    .tab6-container {
        margin-top: 20px;
        padding: 20px;
        border-radius: 20px;
        border: solid 2px #eff1f5;
        background-color: #ffffff;
        .header {
            color: #1050a2;
            font-size: 16px;
            font-family: Kanit-Regular;
            font-weight: bold;
        }
    }
}
