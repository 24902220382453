// Predefined Break-points
// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 436px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */
.register-section-component {
  padding: 8pc;
  align-items: center;
  // background-color: #;

  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.register-side-layer-component-1 {
  z-index: 2;
  position: relative;
  padding-top: 2pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.register-side-layer-component-2 {
  z-index: -1;
  position: relative;
  margin-top: -80px;
  margin-left: 1.2pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.register-logo-layer-component {
  // padding-top: 4pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.register-p-component {
  object-fit: contain;
  font-family: Kanit;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  color: #0c4da2;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.register-info-component {
  display: flex;
  padding-left: 26.5pc;
  padding-bottom: 1pc;

  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.register-button {
  width: 237px;
  height: 45px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
    -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
    inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
    inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
  color: white;
}

.register-login-link {
  color: #0c4da2;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.register-login-link01 {
  object-fit: contain;
  font-family: Kanit;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #8e99ad;
}

.Group-8 {
  // width: 120px;
  flex: 1;
  height: 120px;
  margin: 6px 0px 6px 6px;
  object-fit: contain;
  img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
}

.mobile-icon-register {
  img {
    width: 40px;
    height: 40px;
  }
}
