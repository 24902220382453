#phase2-index {
    .ant-table-thead > tr > th {
        background-color: #0c4da2 !important;
        color: #fff !important;
        font-weight: bold;
    }
    .date-text {
        color: black !important;
    }
    .progressbar-row {
        .status-text-progress {
            color: black !important;
        }
    }
    .process-container {
        svg {
            font-size: 14px;
            margin-right: 5px;
            color: #387436;
        }
        span {
            color: #387436;
            font-size: 14px;
        }
    }
    .project-timeline-body-bottom {
        > div {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            // justify-content: space-between;
            font-size: 18px;
            .process-step {
                color: #666666;
                border-radius: 15px;
                background-color: #e5e6ee;
                padding: 5px 10px !important;
                margin-right: 5px !important;
                white-space: nowrap;
                margin-top: 10px;
                font-size: 11px;
            }
            .process-step.pass {
                background-color: #b4d0b3;
            }

            .process-step.active {
                background-color: #387436;
                color: white;
            }
        }
    }
    label.title {
        font-family: Kanit;
        font-size: 22px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.94;
        letter-spacing: normal;
        text-align: left;
        color: #005;
    }
    .search-component {
        margin-top: 1rem;
        .type-search div:not(div.basic-single):not(div) {
            padding: 10px 20px;
            border: 1px solid #efefef;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #e5e6ee;
            color: #0c4da2;
            white-space: nowrap;
            height: fit-content;
        }
        .type-search div.active {
            padding: 10px 20px;
            border: 1px solid #efefef;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #0c4da2;
            color: #fff;
            white-space: nowrap;
            height: fit-content;
        }
        .ant-input-affix-wrapper {
            font-size: 18px;
            border-radius: 20px;
            padding: 2px 2px 2px 20px;
            .search-button {
                background-color: #0c4da2;
                padding: 10px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    color: #fff;
                }
            }
        }
        .button {
            background-color: white;
            color: #3562fd;
            padding: 10px 20px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            cursor: pointer;
        }
        .button.active {
            background-color: #0e4ea3;
            color: white;
            padding: 10px 20px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            cursor: pointer;
        }
        .button-item {
            padding: 5px 10px;
            background-color: #fff;
            color: #0e4ea3;
            border-radius: 10px;
            font-size: 12px;
            border: 1px solid #ababab;
            height: fit-content;
        }
        .button-item.active {
            background-color: #0e4ea3;
            color: #fff;
        }
    }
    .items-container {
        background-color: white;
        .ant-select-selector {
            border-radius: 20px;
            font-size: 16px;
            padding: 10px 20px;
        }
    }
    .law-container {
        width: 100%;
        border-radius: 10px;
        box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
        border: solid 2px #eff1f5;
        padding: 10px 20px;
        button,
        input,
        select,
        textarea,
        a,
        p,
        label,
        span,
        td,
        tr,
        pre,
        div {
            font-family: BaiJamjuree !important;
        }

        h1,
        h2,
        h3 {
            font-family: BaiJamjuree-SemiBold;
        }

        h4,
        h5 {
            font-family: BaiJamjuree-Bold;
        }
        .description {
            font-family: BaiJamjuree;
            font-size: 14px;
            letter-spacing: normal;
            text-align: left;
            color: #666;
        }
        .title-name {
            font-weight: bold;
        }
    }
    .text-gray {
        color: #6a768a;
    }
    .filter-survey {
        button {
            padding: 5px 10px;
            border-radius: 20px;
            border: 1px solid #efefef;
            background-color: #e5e6ee;
            white-space: nowrap;
        }
        button.active {
            padding: 5px 10px;
            border-radius: 20px;
            border: 1px solid #efefef;
            background-color: #fff;
            white-space: nowrap;
        }
        .survey-type-1 {
            padding: 5px 7px;
            border-radius: 100%;
            background: #0e4ea3;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .survey-type-2 {
            padding: 5px 7px;
            border-radius: 100%;
            background: #438940;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .survey-type-3 {
            padding: 5px 7px;
            border-radius: 100%;
            background: #a38751;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .survey-item-container {
        border-radius: 14px;
        box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
        border: solid 2px #eff1f5;
        background-color: #fff;
        .title-container {
            padding: 10px 20px;
            border-bottom: 1px solid #efefef;
            .title-name {
                font-weight: bold;
            }
        }
        .content-container {
            padding: 10px 20px;
        }
    }
    .search-advance-container {
        .ant-input {
            background-color: #e5e6ee;
            font-size: 16px;
            padding: 10px 20px;
            border-radius: 10px;
        }
        .button-item {
            padding: 10px 15px;
            background-color: #fff;
            color: #0e4ea3;
            border-radius: 10px;
            box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
        }
        .button-item.active {
            background-color: #0e4ea3;
            color: #fff;
        }
        .type-search div {
            padding: 10px 20px;
            border: 1px solid #efefef;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #e5e6ee;
            color: #0c4da2;
            white-space: nowrap;
            height: fit-content;
        }
        .type-search div.active {
            padding: 10px 20px;
            border: 1px solid #efefef;
            border-radius: 20px;
            margin-right: 10px;
            background-color: #0c4da2;
            color: #fff;
            white-space: nowrap;
            height: fit-content;
        }
        .button {
            background-color: white;
            color: #3562fd;
            padding: 10px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .button.active {
            background-color: #0e4ea3;
            color: white;
            padding: 10px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .button-close {
            background-color: #0e4ea3;
            color: white;
            padding: 5px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            cursor: pointer;
            font-size: 16px;
        }
    }
    .search-char-container {
        .type {
            padding: 20px 20px;
            background-color: #e5e6ee;
            border-right: 1px solid #efefef;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            color: #0c4da2;
        }
        .type.left {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        .type.right {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .type.active {
            color: white;
            background-color: #0c4da2;
        }
        .char {
            // padding: 5px 15px;

            background-color: white;
            box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
            color: #0c4da2;
            font-size: 20px;
            border-radius: 10px;
            cursor: pointer;
        }
        .char.active {
            background-color: #0c4da2;
            color: white;
        }
        .button {
            background-color: white;
            color: #3562fd;
            padding: 10px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .button.active {
            background-color: #0e4ea3;
            color: white;
            padding: 10px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            font-size: 16px;
        }
        .button-close {
            background-color: #0e4ea3;
            color: white;
            padding: 5px 20px;
            min-width: 150px;
            white-space: nowrap;
            border: 1px solid #d9d9d9;
            border-radius: 20px;
            cursor: pointer;
            font-size: 16px;
        }
    }
    .right-component {
        button,
        input,
        select,
        textarea,
        a,
        p,
        label,
        span,
        td,
        tr,
        pre,
        div {
            font-family: BaiJamjuree !important;
        }

        h1,
        h2,
        h3 {
            font-family: BaiJamjuree-SemiBold;
        }

        h4,
        h5 {
            font-family: BaiJamjuree-Bold;
        }
    }
}
