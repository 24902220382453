#project-timeline-v2 {
  .menu-top {
    margin-bottom: 20px;
    div.back-button {
      padding: 5px 20px;
      border-radius: 20px;
      border: solid 2px #eff1f5;
      background-color: #ffffff;
    }
    div.project-name {
      color: #fff;
      font-size: 16px;
      margin-left: 10px;
      font-weight: bold;
    }
  }
  .project-timeline-container {
    border-radius: 10px;
    border: solid 2px #eff1f5;
    background-color: #ffffff;

    .project-timeline-body-top {
      padding: 20px;
      border-bottom: 1px solid #eff1f5;
      .project-date {
        color: #333333;
        font-size: 0.8rem;
      }
      .project-name {
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.48;
        letter-spacing: normal;
        text-align: left;
        color: #387436;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 20px;
        overflow: hidden;
      }
      .project-type {
        color: #0c4da2;
      }
    }
    .project-timeline-body-bottom {
      padding: 20px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .process-step {
          color: #666666;
          border-radius: 15px;
          background-color: #e5e6ee;
          padding: 5px;
          margin-right: 5px;
          white-space: nowrap;
          margin-top: 10px;
        }
        .process-step.pass {
          background-color: #b4d0b3;
        }

        .process-step.active {
          background-color: #387436;
          color: white;
        }
      }
    }
  }

  .project-timeline-detail {
    border-radius: 10px;
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    .project-timeline-body-top {
      padding: 10px;
      border-bottom: 1px solid #eff1f5;
      .project-date {
        color: #333333;
        font-size: 0.6rem;
      }
      .project-name {
        font-size: 0.8rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.48;
        letter-spacing: normal;
        text-align: left;
        color: #387436;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 20px;
        overflow: hidden;
      }
      .project-type {
        color: #0c4da2;
      }
    }
    .project-timeline-body-bottom {
      padding: 10px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        .process-step {
          color: #666666;
          border-radius: 15px;
          background-color: #e5e6ee;
          padding: 5px;
          margin-right: 5px;
        }
        .process-step.pass {
          background-color: #b4d0b3;
        }

        .process-step.active {
          background-color: #387436;
          color: white;
        }
      }
      .description {
        padding: 10px;
        object-fit: contain;
        border-radius: 10px;
        border: solid 2px #eff1f5;
        background-color: #f1f3f7;
        > p {
          color: #6d7481;
          font-size: 1rem;
        }
      }
      .file-container {
        border: solid 2px #eff1f5;
        background-color: #ffffff;
        padding: 10px;
        .filename {
          color: #959aa5;
          font-size: 0.6rem;
          span.filename-text {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            max-height: 20px;
            overflow: hidden;
          }
          svg {
            margin-right: 5px;
          }
        }
        .download {
          color: #387436;
          font-size: 0.6rem;
          svg {
            margin-right: 5px;
          }
        }
      }
      .check-container {
        padding: 10px;
        object-fit: contain;
        border-radius: 10px;
        border: solid 2px #eff1f5;
        background-color: #f1f3f7;
        label {
          color: #6d7481;
          font-size: 0.6rem;
        }
      }
      .process-update {
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        object-fit: contain;
        border-radius: 10px;
        background-color: #f7dcdc;
        span {
          color: #b33030;
          font-size: 0.7rem;
        }
      }
    }
  }
  .project-timeline-detail.disabled {
    background-color: #efefef;
  }

  .card-survey-mobile {
    .container-survey {
      min-height: 224px;
      margin: 15px 14px 0px 12px;
      padding: 14px 10px 14px 10px;
      object-fit: contain;
      border-radius: 10px;
      box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
      border: solid 2px #eff1f5;
      background-color: #ffffff;
    }
    .favorite-icon {
      width: 36px;
      height: 36px;
      border-radius: 18px;
      box-shadow: 2.8px 2.8px 5px 0 rgba(137, 148, 169, 0.2), -2.1px -2.1px 8px 0 rgba(137, 148, 169, 0.15),
        inset 3.5px 3.5px 10px 0 #ffffff, inset -3.5px -3.5px 10px 0 rgba(188, 194, 211, 0.25);
      background-color: #ffffff;
    }
    .law-icon {
      width: 40px;
      height: 40px;
      // padding: 18px 22px 20px 31px;
      border-radius: 20px;
      border: solid 3px #85b0e8;
      background-color: #0c4da2;
    }
    .agency-container {
      height: 30px;
      margin: 0px 0px 0px 5px;
      padding: 10px;
      object-fit: contain;
      border-radius: 15px;
      box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15), inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
      background-color: #f4f6f9;
      display: flex;
      justify-content: center;
      align-items: center;
      label {
        font-size: 12px;
      }
      img {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }
    .project-name {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      max-height: 20px;
      color: #0c4da2;
    }
    .survey-name {
      font-size: 14px;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-height: 40px;
      color: #666666;
      margin-top: 10px;
    }
    .text-status {
      color: #488345;
      font-size: 12px;
    }
    .user-status {
      margin-right: 10px;
    }
    .text-number {
      color: #494949;
      font-size: 12px;
      margin-right: 5px;
      font-weight: bold;
    }
    @media screen and (max-width: 320px) {
      .text-status {
        color: #488345;
        font-size: 12px;
      }
      .user-status {
        margin-right: 6px;
      }
      .text-number {
        color: #494949;
        font-size: 9px;
        margin-right: 0px;
        font-weight: bold;
      }
    }
    @media screen and (min-width: 390px) and (max-width: 767px) {
      .text-status {
        color: #488345;
        font-size: 12px;
      }
      .user-status {
        margin-right: 6px;
      }
      .text-number {
        color: #494949;
        font-size: 9px;
        margin-right: 0px;
        font-weight: bold;
      }
    }
  }

  .card-filter {
    background: #ffffff;
    padding: 24px;
    box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    border-radius: 10px;
    align-items: center;
    display: flex;
    z-index: 4;
    margin-top: 0px;
    @media screen and (max-width: 400px) {
      margin-top: 20px;
      width: 90%;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .share-icon:hover {
    color: #0c4da2;
  }

  .badge-public {
    margin-right: 5px;
    padding: 2px 7.5px;
    border-radius: 5px;
    background-color: #0e4ea3;
    color: #fff;
  }
}
