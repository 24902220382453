// Predefined Break-points
// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 436px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */
.register-section-component {
  padding: 8pc;

  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.login-side-layer-component-1 {
  z-index: 2;
  position: relative;
  padding-top: 2pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.login-side-layer-component-2 {
  z-index: -1;
  position: relative;
  margin-top: -80px;
  margin-left: 1.2pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.login-logo-layer-component {
  padding-top: 4pc;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.Rounded-Rectangle-8-login {
  width: 400px;
  height: 55px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
    -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
    inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
    inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
}

.Rounded-Rectangle-8-login02 {
  width: 400px;
  height: 55px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
    -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
    inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
    inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05);
  background-color: #e3d6bd;
}

.arrow-left-regular {
  width: 14px;
  height: 14px;
  margin: 2px 8px 0 25px;
  object-fit: contain;
  color: #8e99ad;
}

.login-back-to-register {
  width: fit-content;
  height: 17px;
  margin: 0px 0px 0 8px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #8e99ad;
  cursor: pointer;
}

.-copy {
  width: 158px;
  height: 24px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #847049;
}

.login-label {
  width: 86px;
  height: 24px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fdfeff;
}

.section {
  display: grid;
  justify-content: center;
  height: 100%;
}

.regis-1 {
  width: 220px;
  height: 150px;
  margin: 0px 0px 0px 0px;
  object-fit: contain;
  cursor: pointer;
  border-bottom: 8px solid #eff1f5;
  border-radius: 1.8px;
}

.regis-2 {
  width: 220px;
  height: 150px;
  margin: 0px 0px 0px 0px;
  object-fit: contain;
  cursor: pointer;
  border-bottom: 8px solid #0c4da2;
  border-radius: 1.8px;
}

.tabname-container-web01 {
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    object-fit: contain;
    font-family: Kanit;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 6.39;
    letter-spacing: normal;
    text-align: center;
    color: #0c4ea4;
  }
  @include media('screen', '<minWidth') {
  }
}

.tabname-container-web02 {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #0c4ea4;
  margin-top: 4pc;
  line-height: 1.5;
}

.tabname-container-web03 {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 7.39;
  letter-spacing: normal;
  text-align: center;
  color: #0c4ea4;
}

.form-containe-year-select {
  margin-left: 9pc;
  padding-top: 1pc;
  width: 305px;
}

.form-container-year-label {
  width: 305px;
  padding-top: 1.5pc;
  padding-left: 150px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c4ea4;
}

.form-containe-sex-select {
  margin-left: 5pc;
  padding-top: 1pc;
  width: 305px;
}

.form-container-sex-label {
  width: 305px;
  padding-top: 1.5pc;
  padding-left: 80px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c4ea4;
}

.form-containe-education-select {
  margin-left: 9pc;
  padding-top: 0pc;
  width: 690px;
}

.form-container-education-label {
  width: 690px;
  padding-left: 9pc;
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  line-height: 4;
  color: #0c4ea4;
}

.Input-Label-copy {
  object-fit: contain;
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6d7481;
  padding-right: 30px;
  margin-top: 10px;
}

.radio-container {
  padding-left: 9pc;
}

.login-Rectangle-8-copy {
  width: 112px;
  height: 45px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(165, 154, 104, 0.15),
    -2.8px -2.8px 9px 0 rgba(227, 214, 189, 0.17),
    inset 6.9px 1.1px 18px 0 rgba(219, 204, 145, 0.15),
    inset -9.2px -9.2px 26px 0 rgba(240, 225, 157, 0.05);
  background-color: #e3d6bd;
  margin-right: 1pc;
}

.login-Rounded-Rectangle-8 {
  width: 112px;
  height: 45px;
  // margin: 20px 26px 0 18px;
  // padding: 15px 36px 14px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
    -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
    inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
    inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
  color: white;
}

.forward-duotone {
  width: 22px;
  height: 16px;
  margin: 1px 13px 0 0;
  object-fit: contain;
  color: #847049;
}

.parent {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: fit-content;
  justify-content: center;
}

.child {
  width: fit-content;
  margin: 1pc;
}

.Possitive-Message {
  padding-left: 19.5pc;
  padding-top: 1pc;
}

.Possitive-radio {
  padding-left: 410px;
  padding-top: 1pc;
}

.Possitive-label {
  padding-left: 390px;
  padding-top: 1pc;
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.39;
  letter-spacing: normal;
  text-align: left;
  color: #0c4ea4;
}

.forgot-password {
  font-family: Kanit;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #8e99ad;
  text-decoration: underline;
}

.card {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  float: left;
  margin-left: 20px;
  width: 700px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ffff;
}

.card-buttom {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  float: left;
  margin-left: 20px;
  width: 700px;
  height: 64px;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffff;
}

.card-name {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  float: left;
  width: 1024px;
  // height: 864px;
  padding: 40px 10px 100px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #ffff;
}

.card-buttom-bottom {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  float: left;
  width: 1024px;
  height: 64px;
  padding: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ffff;
}

.card p {
  font-size: 18px;
}

.card-buttom p {
  font-size: 18px;
}
.cardContainer:after {
  content: '';
  display: table;
  clear: both;
}
@media screen and (max-width: 600px) {
  .card {
    width: 100%;
  }
  .card-bottom {
    width: 100%;
  }
  .card-name {
    width: 100%;
  }
}

.login-card-container {
  display: flex;
  justify-content: center;
  margin: 151px 0 0 0;
}

.login-card-bottom-container {
  display: flex;
  justify-content: center;
  margin: 0 0 164px 0;
}

.login-side-card-images {
  position: absolute;
  left: 428px;
  top: 30px;
}

.login-card-logo {
  display: flex;
  justify-content: center;
  margin: 75px 0 17px 0;
}

.label-big {
  object-fit: contain;
  font-family: Kanit;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0c4da2;
}

.label-small {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6d7481;
}

.label-small-underlne {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18.5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #6d7481;
  text-decoration: underline;
}

.label-small-underlne-position {
  margin: 18px 0 15px 490px;
}

.login-name-container {
  display: flex;
  justify-content: center;
  margin: 151px 0 0 0;
}

.login-name-thriple-logo {
  display: flex;
  // margin: 99px 0 0 209px;
}

.login-name-tab1-container {
  margin: 70px 0 0 201px;
}

.login-name-tab1-input-container {
  display: flex;
  // align-items: center;
}

.login-name-list-container {
  margin: 118px 0 50px 155px;
}

.login-name-list-scholler {
  max-height: 500px;
  overflow-y: scroll;
  margin: 0 0 53px 0;
}

.login-name-list-scholler::-webkit-scrollbar {
  display: none;
}
