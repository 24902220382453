// Predefined Break-points
// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 436px;

@function translate-media-condition($c) {
  $condMap: (
    "screen": "only screen",
    "print": "only print",
    "retina":
      "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
    ">maxWidth": "(min-width: #{$mediaMaxWidth + 1})",
    "<maxWidth": "(max-width: #{$mediaMaxWidth})",
    ">bp1Width": "(min-width: #{$mediaBp1Width + 1})",
    "<bp1Width": "(max-width: #{$mediaBp1Width})",
    ">minWidth": "(min-width: #{$mediaMinWidth + 1})",
    "<minWidth": "(max-width: #{$mediaMinWidth})",
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: "";
  @each $arg in $args {
    $op: "";
    @if ($query != "") {
      $op: " and ";
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

@font-face {
  font-family: "Kanit";
  src: local("Kanit"), url(./assets/fonts/Kanit-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Kanit-SemiBold";
  src: local("Kanit-SemiBold"), url(./assets/fonts/Kanit-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Kanit-Bold";
  src: local("Kanit-Bold"), url(./assets/fonts/Kanit-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Kanit-Regular";
  src: local("Kanit-Regular"), url(./assets/fonts/Kanit-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "BaiJamjuree";
  src: local("BaiJamjuree"), url(./assets/fonts/BaiJamjuree-Light.ttf) format("truetype");
}

@font-face {
  font-family: "BaiJamjuree-SemiBold";
  src: local("BaiJamjuree-SemiBold"), url(./assets/fonts/BaiJamjuree-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "BaiJamjuree-Bold";
  src: local("BaiJamjuree-Bold"), url(./assets/fonts/BaiJamjuree-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "BaiJamjuree-Regular";
  src: local("BaiJamjuree-Regular"), url(./assets/fonts/BaiJamjuree-Regular.ttf) format("truetype");
}

button,
input,
select,
textarea,
a,
p,
label,
span,
td,
tr,
pre,
div {
  font-family: Kanit !important;
}

h1,
h2,
h3 {
  font-family: Kanit-SemiBold;
}

h4,
h5 {
  font-family: Kanit-Bold;
}

.font-baijamjuree {
  font-family: BaiJamjuree !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

pre {
  overflow: hidden !important;
  white-space: break-spaces !important;
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-1 {
  flex: 1;
}

div#root {
  overflow-x: hidden !important;
}

@media (min-width: 320px) {
  .MuiToolbar-regular {
    min-height: 45px !important;
  }
  .logo-topbar {
    height: 32px;
  }
  .section-banner {
    margin: 65px 0px 0px 0px;
    padding: 10px;
  }
}

@media (min-width: 768px) {
  .MuiToolbar-regular {
    min-height: 80px !important;
  }
  .logo-topbar {
    height: 70px;
  }
  .section-banner {
    margin: 85px 0px 0px 0px;
    padding: 10px;
  }
}

.text-theme-blue {
  color: #0c4da2 !important;
}

.MuiTypography-body1 {
  font-family: Kanit !important;
}

.w-100 {
  width: 100%;
}

.share-container {
  height: 170px;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1299;
  background: white;
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
  }
  .header {
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: #ccc 1px solid;
    span {
      font-family: Kanit-Regular;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: center;
      color: #0c4ea4;
    }
  }
  .share-button {
    margin-top: 22px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      div.circle {
        border: solid 1px #b0b8c6;
        border-radius: 20px;
        background-color: #ffffff;
        padding: 10px;
        margin-bottom: 5px;
      }
    }
    span {
      font-family: Kanit;
      font-size: 11.5px;
      color: #697288;
    }
    svg {
      border-radius: 5px;
    }
  }
  .copy-button {
    margin-top: 22px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: #ccc 1px solid;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      div.circle {
        border: solid 1px #b0b8c6;
        border-radius: 20px;
        background-color: #ffffff;
        padding: 10px;
      }
    }
    span {
      font-family: Kanit;
      font-size: 11.5px;
      color: #697288;
    }
  }
}

.bottom-button {
  height: 66px;
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1288;
  background: white;
  box-shadow: 2.5px 1.7px 8px 0 rgba(12, 77, 162, 0.23), 5.7px 2px 30px 0 rgba(39, 176, 223, 0.25),
    inset 9.2px 9.2px 26px 0 #ffffff, inset -9.2px -9.2px 26px 0 rgba(105, 217, 255, 0.15);
  padding: 10px 20px;
  @include media("retina") {
  }
  @include media("screen", ">bp1Width", "<maxWidth") {
  }
  @include media("screen", ">minWidth", "<bp1Width") {
  }
  @include media("screen", "<minWidth") {
  }
  div {
    height: 100%;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3), -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36), inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
    font-size: 12px;
    color: white;
    background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
    span {
      font-size: 16px;
      font-family: Kanit-Regular;
      margin-left: 8px;
    }
  }
  div.disabled {
    background-color: #6d7481;
    pointer-events: none;
    background-image: none;
    box-shadow: none;
  }
  div.summary {
    box-shadow: 4.9px 4.9px 30px 0 rgba(65, 135, 67, 0.3), -6.6px -4.6px 30px 0 rgba(84, 187, 80, 0.25),
      inset 6.9px 1.1px 18px 0 rgba(81, 196, 91, 0.36), inset -9.2px -9.2px 26px 0 rgba(39, 107, 41, 0.15);
    background-color: #387436;
    background-image: none;
  }
}

.project-head {
  object-fit: contain;
  font-family: Kanit-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: left;
  color: #0c4da2;
  font-weight: bold;
  // text-decoration: underline;
}

.MuiDrawer-paper {
  z-index: 999999;
  max-width: 250px;
}

@media (min-width: 320px) {
  .MuiDrawer-paper {
    max-width: 250px;
  }
}

@media (min-width: 768px) {
  .MuiDrawer-paper {
    max-width: 350px;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.form-ant {
  height: 40px;
  border-radius: 5px;
  border: solid 1px #dbdcde;
  background-color: #fefefe;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
}

.form-ant:read-only {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}

.hr {
  margin-top: 20px;
  margin-bottom: 10px;
  height: 3px;
  background-color: #e2e4eb;
}

.bg-button {
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4) !important;
  width: 100%;
  height: 44px !important;
  border-radius: 5px !important;
  span {
    color: white;
  }
}

.ant-avatar {
  img {
    border: 1px solid #bfbfbf;
    border-radius: 16px;
  }
}

.ant-list-item-meta {
  align-items: center !important;
  margin-top: 10px;
}

.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0px !important;
}

.ant-list-item-meta-avatar {
  margin-right: 10px;
}

.ant-list-lg .ant-list-item {
  padding: 16px 16px !important;
}

.react-actionsheet-wrap {
  z-index: 99999 !important;
  background: white;
  opacity: 1;
}

.react-actionsheet-menu {
  max-height: 300px !important;
  overflow-x: auto !important;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-align-center {
  display: flex;
  align-items: center;
}

.d-flex-justify-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.home-button-bottom {
  // padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #cfcfcf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  height: 66px;
  width: 100%;
  align-items: center;
}

.text-danger {
  color: red;
}
.text-regular {
  font-family: Kanit-Regular !important;
}
.text-primary {
  color: #0c4da2 !important;
}
.image-profile-165 {
  height: 165px;
  width: 165px;
  border-radius: 85;
}

.bottom-navigation {
  position: fixed;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  width: 100%;
  height: 66px;
  padding: 20px;
  box-shadow: 2.5px 1.7px 8px 0 rgba(12, 77, 162, 0.23), 5.7px 2px 30px 0 rgba(39, 176, 223, 0.25),
    inset 9.2px 9.2px 26px 0 #ffffff, inset -9.2px -9.2px 26px 0 rgba(105, 217, 255, 0.15);
  label {
    color: #5e646e;
    font-size: 12px;
    margin-top: 5px;
  }
  img {
    height: 24px;
    width: 24px;
    opacity: 1;
  }
  img.active {
    opacity: 1;
  }
}

#root {
  min-height: 100%;
}

#g-recaptcha {
  margin: 10px;
  // display: flex;
  // justify-content: center;
}

.back-btn {
  margin-bottom: 10px;

  color: #0c4da2;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tag-openid {
  position: relative;
  /* margin-right: 20px; */
  left: -20px;
  top: 20px;
}
.law-group-main {
  span {
    color: #0c4da2;
  }
}
.law-group-container {
  border: solid 5px #85b0e8;
  background-color: #0c4da2;
  height: 100px;
  width: 100px;
  border-radius: 50px;
}
.law-group-container-mobile {
  border: solid 3px #85b0e8;
  background-color: #0c4da2;
  height: 46px;
  width: 46px;
  border-radius: 23px;
}

.law-group-container.outlaw,
.law-group-container-mobile.outlaw {
  border: solid 5px #ebe3d3;
  background-color: #e3d6bc;
}

.avatar-object-contain {
  img {
    object-fit: contain !important;
    border: none !important;
  }
}

.slick-dots li button:before {
  font-size: 10px !important;
}

.slick-dots li.slick-active button:before {
  color: #0c4da2 !important;
}

.expand-survey-icon {
  padding: 10px 15px;
  border: solid 2px #eff1f5;
  background-color: #ffffff;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    color: #6d7481;
    margin-right: 10px;
  }
  svg {
    font-size: 20px;
  }
}

.text-filter {
  display: flex;
  align-items: center;
  label {
    font-size: 16px;
    color: #6d7481;
    font-weight: bold;
  }
  svg {
    font-size: 20px;
    margin-right: 10px;
  }
}

.clear-filter {
  display: flex;
  align-items: center;
  cursor: pointer;
  label {
    font-size: 16px;
    color: #0c4da2;
  }
  svg {
    font-size: 20px;
    margin-right: 10px;
  }
}

.surveyType-container {
  display: flex;
  align-items: center;
  white-space: nowrap;

  span {
    font-size: 16px;
    color: #0c4da2;
  }
  svg {
    height: 20px;
    width: 20px;
    margin-right: 5px;
    object-fit: contain;
    color: #0c4da2;
  }
}

.ant-modal-body {
  max-height: 500px !important;
  overflow: auto !important;
}

.project-timeline-body-bottom {
  .entity-text {
    font-weight: bold;
    color: #488345;
  }
}

.project-container-home-page {
  .project-timeline-body-bottom {
    font-size: 15px;
    @media only screen and (max-width: 400px) {
      font-size: 12px;
    }

    img {
      width: 35px;
      height: 27px;
    }
    svg {
      width: 35px;
      height: 27px;
    }

    //mobile
    @media only screen and (max-width: 420px) {
      img {
        width: 23px;
        height: 18px;
      }
      svg {
        width: 23px;
        height: 18px;
      }
      label {
        font-size: 13px;
      }
    }
    //mobile
    @media only screen and (max-width: 420px) {
      div.process-step {
        font-size: 12px;
      }
    }

    > div {
      div {
        align-items: center;
      }
    }
  }
}

.project-card-desktop {
  border-radius: 10px;
  min-height: 190px;
  background: #ffffff;
  padding: 24px;
  box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
  //box-shadow: 1px 3.9px 16px 0 rgb(137 148 169 / 24%);
  border: solid 2px #eff1f5;
  background-color: #ffffff;
  .law-group-container {
    height: 90px !important;
    width: 90px !important;
  }
  .project-card-body {
    svg {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    div {
      align-items: center;
    }
    div.tag {
      color: #0c4da2;
      margin-right: 20px;
      span {
        font-weight: bold;
        font-size: 18px;
      }
    }
    div.process-now {
      color: #488345;
      margin-right: 20px;
      span {
        font-weight: bold;
        font-size: 18px;
      }
    }
    div.calendar {
      span {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .project-timeline-body-bottom {
    .process-step {
      font-size: 17px !important;
      padding-left: 20px;
      padding-right: 20px;
    }
    .process-step.active {
      font-size: 20px !important;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .project-name {
    color: #488345 !important;
    flex: 1;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .law_publish_title {
    padding: 5px 15px;
    border-radius: 22px;
    background-color: #e3d6bc;
    span.ant-avatar {
      margin-right: 10px;
    }
    font-size: 14px;
    margin-bottom: 10px;
  }
  .law_publish_link {
    label {
      font-size: 14px;
      color: #9c824f;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.project-card-desktop.law-cancal {
  .law-group-container {
    border: solid 5px #eef0fb !important;
    background-color: #e5e6ee !important;
    img {
      opacity: 0.5;
    }
  }
  label,
  .entity-text {
    color: #666666 !important;
  }
  .process-container {
    svg {
      color: #666666 !important;
    }
  }
  .process-step {
    background-color: #cfcfcf !important;
    color: #333 !important;
  }
  .process-step.active {
    background-color: #333 !important;
    color: #fff !important;
  }
  .tag {
    svg {
      color: #666666 !important;
    }
    span {
      color: #666666 !important;
    }
  }
}

.project-card-mobile {
  min-height: 210px;
  margin: 15px 14px 0px 12px;
  padding: 14px 10px 14px 10px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
  border: solid 2px #eff1f5;
  background-color: #ffffff;
  .project-name {
    font-size: 18px !important;
    max-height: 30px !important;
    color: #488345 !important;
  }
  .process-step.active {
    // padding-left: 20px;
    // padding-right: 20px;
    font-size: 16px !important;
  }
}

.text-link {
  color: #0c4da2 !important;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

// .cwc-widget-icon {
//   position: absolute;
//   bottom: 60px;
//   right: 5px;
// }
.cwc-cookie-banner-ui-sdk > div:nth-of-type(1) {
  position: fixed;
  bottom: 80px;
  right: 15px;
}

.cwc-cookie-banner-ui-sdk > div.cwc-float-container {
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

.process-step.iscancel {
  background-color: #cfcfcf !important;
  color: #333 !important;
}
.process-step.iscancel.active {
  background-color: #333 !important;
  color: #fff !important;
}

.ant-image-preview-img {
  max-width: 80% !important;
  max-height: 80% !important;
}

.page-template-page-landing-php {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.landing-block {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  min-height: 90vh;
}

.landing-block .photo,
.landing-block .text,
.landing-block .link {
  text-align: center;
}

@media (min-width: 640px) {
  .landing-block {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

.mt-1 {
  margin-top: 1rem;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.line-clamp-4 {
  -webkit-line-clamp: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

mark {
  background-color: yellow !important;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;

  .page-link {
    position: relative;
    display: block;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    background-color: #fff;
    // border: 1px solid #dee2e6;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  .page-item:not(:first-child) .page-link {
    margin-left: -1px;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #0c4da2;
    border-color: #0c4da2;
  }
  .page-link {
    padding: 0.275rem 0.75rem;
  }
}
