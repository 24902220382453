#survey-detail-page {
    padding-bottom: 100px;
    .menu-top {
        margin-bottom: 20px;
        div.back-button {
            padding: 5px 20px;
            border-radius: 20px;
            border: solid 2px #eff1f5;
            background-color: #ffffff;
        }
        div.project-name {
            color: #fff;
            font-size: 16px;
            margin-left: 10px;
            font-weight: bold;
        }
    }

    .rule-container {
        margin-top: 20px;
        padding: 20px;
        border-radius: 20px;
        border: solid 2px #eff1f5;
        background-color: #ffffff;
        align-items: center;
        justify-items: center;
        .title {
            font-size: 20px;
            flex: 1;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #0c4da2;
            padding-bottom: 10px;
            border-bottom: 1px solid #eff1f5;
            margin-bottom: 10px;
        }
    }
    .rule-content {
        .title-content {
            font-size: 14px;
            flex: 1;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: left;
            color: #0c4da2;
        }
    }
    .menu-container {
        padding: 20px 20px 0px 20px;
        border-radius: 20px;
        border: solid 2px #eff1f5;
        background-color: #ffffff;
        margin-top: 20px;
        flex-wrap: wrap;
        div {
            cursor: pointer;
            // border-right: solid 2px #eff1f5;
            padding-right: 20px;
            padding-left: 20px;
            white-space: nowrap;
            margin-bottom: 15px;
            padding-bottom: 5px;
        }
        div.active {
            border-bottom: 3px solid #1c56a3;
        }
    }
    @media (min-width: 600px) {
        .MuiContainer-root {
            padding-left: 20px !important;
            padding-right: 20px !important;
        }
    }

    .project-name-2-line {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

#survey-detail-page {
    .filter-container {
        min-width: 300px;
        margin-left: 50px;
        margin-bottom: 20px;
        .search {
            .ant-input {
                height: 50px;
                border-radius: 5px 0px 0px 5px;
            }
            button {
                height: 50px;
                width: 50px;
                border-radius: 0px 5px 5px 0px;
            }
        }
    }
    .survey-card-container {
        flex: 1;

        .survey-card {
            // background-color: #fff;
            // padding: 20px;
            display: flex;
            // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            // background-color: #fff;
            min-height: 230px;
            .column1 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                .project-type {
                    display: flex;
                    svg {
                        height: 18px;
                        width: 21px;
                        color: #0c4da2;
                    }
                    span {
                        margin-left: 5px;
                        color: #0c4da2;
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
                .organize-container img {
                    width: 26px;
                    object-fit: contain;
                    height: 26px;
                    margin-right: 5px;
                }
            }
            .column2 {
                display: flex;
                flex-direction: column;
                flex: 1;
                .title {
                    font-size: 20px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: left;
                    color: #0c4da2;
                }
                .description {
                    margin-top: 15px;
                    font-size: 18px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.67;
                    letter-spacing: normal;
                    text-align: left;
                    color: #333;
                }
                .webboard-row {
                    margin-top: 15px;
                    display: flex;
                    font-size: 14px;
                    .lawgroup-text {
                        max-width: 200px;
                        overflow: hidden;
                        display: inline-block;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    label {
                        font-size: 14px;
                    }
                    span {
                        font-size: 14px;
                    }
                    .badge-answer {
                        margin-left: 5px;
                        padding: 2px 7.5px;
                        border-radius: 5px;
                        background-color: #488345;
                        color: #fff;
                    }
                    .badge-public {
                        margin-left: 5px;
                        padding: 2px 7.5px;
                        border-radius: 5px;
                        background-color: #0e4ea3;
                        color: #fff;
                    }
                }
                .progressbar-row {
                    display: flex;
                    flex: 1;
                    align-self: flex-end;
                    justify-content: flex-end;
                    justify-items: flex-end;
                    align-items: flex-end;
                    width: 100%;
                    span {
                        white-space: nowrap;
                    }
                    span,
                    label {
                        color: #666666;
                    }
                    .expand {
                        align-items: center;
                        padding-left: 7.5px;
                        border-left: 2px solid #e5e6ee;
                    }
                    .badge-public {
                        margin-right: 5px;
                        padding: 2px 7.5px;
                        border-radius: 5px;
                        background-color: #0e4ea3;
                        color: #fff;
                    }
                }
            }
        }
    }
    .file-container {
        div {
            background-color: #f0f2f6;
            padding: 10px 20px;
            border-radius: 10px;
            color: #333;
        }
    }
}
