// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */

.scroll-container {
  @include media('retina') {
    top: 300;
    left: 2%;
    right: 0%;
    min-width: 1320px;
    height: 18px;
    background: repeating-linear-gradient(
        to right,
        #ddd,
        #ddd 4%,
        transparent 4%,
        transparent 5%
      ),
      repeating-linear-gradient(
        to right,
        #0c50a7,
        #39bfe5 8%,
        transparent 8%,
        transparent 10%
      );
    background-size: 200%, 100%;
    background-repeat: repeat-y;
    object-fit: contain;
    padding-left: 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
      inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    top: 300;
    left: 2%;
    right: 0%;
    max-width: 790px;
    min-width: 970px;
    height: 18px;
    background: repeating-linear-gradient(
        to right,
        #ddd,
        #ddd 4%,
        transparent 4%,
        transparent 5%
      ),
      repeating-linear-gradient(
        to right,
        #0c50a7,
        #39bfe5 8%,
        transparent 8%,
        transparent 10%
      );
    background-size: 200%, 100%;
    background-repeat: repeat-y;
    object-fit: contain;
    padding-left: 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
      inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    top: 300;
    left: 2%;
    right: 0%;
    max-width: 770px;
    min-width: 730px;
    height: 18px;
    background: repeating-linear-gradient(
        to right,
        #ddd,
        #ddd 4%,
        transparent 4%,
        transparent 5%
      ),
      repeating-linear-gradient(
        to right,
        #0c50a7,
        #39bfe5 8%,
        transparent 8%,
        transparent 10%
      );
    background-size: 200%, 100%;
    background-repeat: repeat-y;
    object-fit: contain;
    padding-left: 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
      inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
  }
  @include media('screen', '<minWidth') {
    top: 300;
    left: 2%;
    right: 0%;
    min-width: 370px;
    height: 12px;
    background: repeating-linear-gradient(
        to right,
        #ffffff,
        #ffffff 4%,
        transparent 5%,
        transparent 5%
      ),
      repeating-linear-gradient(
        to right,
        #0c50a7,
        #39bfe5 8%,
        transparent 8%,
        transparent 10%
      );
    background-size: 200%, 100%;
    background-repeat: repeat-y;
    padding-left: 20px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
      inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
  }
}

// .scroll-container .indicator {
//   height: 100%;
//   background-image: linear-gradient(to top, #0c50a7, #39bfe5);
//   box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
//     inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
//   object-fit: contain;
//   border-radius: 10px;
//   @include media("retina") {
//   }
//   @include media("screen", ">bp1Width", "<maxWidth") {
//     height: 100%;
//     background-image: linear-gradient(to top, #0c50a7, #39bfe5);
//     box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
//       inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
//     object-fit: contain;
//     border-radius: 10px;
//   }
//   @include media("screen", ">minWidth", "<bp1Width") {
//     height: 100%;
//     background-image: linear-gradient(to top, #0c50a7, #39bfe5);
//     box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
//       inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
//     object-fit: contain;
//     border-radius: 10px;
//   }
//   @include media("screen", "<minWidth") {
//     height: 100%;
//     background-image: linear-gradient(to top, #0c50a7, #39bfe5);
//     box-shadow: inset -9.2px -9.2px 25px 0 rgba(137, 148, 169, 0.15),
//       inset 9.2px 9.2px 25px 0 rgba(137, 148, 169, 0.15);
//     object-fit: contain;
//     border-radius: 10px;
//   }
// }

// .container {
//   width: 100%;
//   position: fixed;
//   padding-right: 19px;
//   z-index: 2;
//   background-color: white;
//   @include media("retina") {
//   }
//   @include media("screen", ">bp1Width", "<maxWidth") {
//     width: 100%;
//     position: fixed;
//     padding-right: 19px;
//     z-index: 2;
//     background-color: white;
//   }
//   @include media("screen", ">minWidth", "<bp1Width") {
//     width: 100%;
//     position: fixed;
//     padding-right: 19px;
//     z-index: 2;
//     background-color: white;
//   }
//   @include media("screen", "<minWidth") {
//     width: 100%;
//     position: fixed;
//     padding-right: 19px;
//     background-color: white;
//     z-index: 2;
//   }
// }

// .container2 {
//   width: 100%;
//   position: fixed;
//   padding-right: 19px;
//   z-index: 3;
//   background-color: transparent;
// }

// .item {
//   width: 10%;
//   border: 1px solid grey;
//   background-color: transparent;
//   border-radius: 10px;
//   margin-right: 8px;
//   float: left;
//   display: block;
//   height: 15px;
// }

.step-footer .MuiMobileStepper-root div {
  width: 100% !important;
}
