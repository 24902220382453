#listening-v2 {
    .card-type-filter-container {
        margin: -10px 0 0 0;
        flex-wrap: wrap;
        > * {
            margin-top: 10px;
        }
    }
    .ant-input-affix-wrapper {
        font-size: 18px;
        border-radius: 20px;
        padding: 2px 2px 2px 20px;
        .search-button {
            background-color: #0c4da2;
            padding: 7px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                color: #fff;
            }
        }
    }
    .card-type-filter {
        padding: 10px 20px;
        margin-right: 10px;
        object-fit: contain;
        border-radius: 27px;
        box-shadow: 3.9px 1px 6px 0 rgba(137, 148, 169, 0.4);
        background-color: #fff;
        white-space: nowrap;
    }

    .ant-table-thead > tr > th {
        background-color: #0c4da2 !important;
        color: #fff !important;
        font-weight: bold;
    }
}

.evaluate-button {
    height: 34px;
    border-radius: 5px;
    padding: 0px 20px;
    background: #f6ffed;
    color: #52c425;
    border: 1px solid #b7eb8f;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        font-size: 20px;
    }

    &:hover {
        background: #387436;
        color: #fff;
        border: 1px solid #387436;
    }
}

.evaluate-label {
    font-size: 14px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
