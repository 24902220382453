// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */
.display-images-none {
  display: none;
  @include media('retina') {
    display: none;
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    display: none;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    display: none;
  }
  @include media('screen', '<minWidth') {
    display: none;
  }
}

.status-text {
  font-size: 16px;
  color: #ffffff;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    font-size: 16px;
    color: #ffffff;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    font-size: 16px;
    color: #ffffff;
  }
  @include media('screen', '<minWidth') {
    width: fit-content;
    height: 5px;
    object-fit: contain;
    font-family: Kanit;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
}

.status-round-texts {
  width: fit-content;
  height: 18px;
  margin: 10px 10px 16px 9px;
  padding: 2px 6px 2px 9px;
  object-fit: contain;
  border-radius: 9px;
  background-color: #0c4da2;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: fit-content;
    height: 18px;
    margin: 10px 10px 16px 9px;
    object-fit: contain;
    border-radius: 9px;
    background-color: #0c4da2;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: fit-content;
    height: 18px;
    margin: 10px 10px 16px 9px;
    padding: 2px 6px 2px 9px;
    object-fit: contain;
    border-radius: 9px;
    background-color: #0c4da2;
  }
  @include media('screen', '<minWidth') {
    width: fit-content;
    height: 16px;
    margin: 10px 10px 16px 9px;
    padding: 0px 6px 2px 9px;
    object-fit: contain;
    border-radius: 9px;
    background-color: #0c4da2;
  }
}

.icon-comment-alt-edit {
  height: 26px;
  padding-right: 5px;
  object-fit: contain;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    height: 26px;
    padding-right: 5px;
    object-fit: contain;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    height: 26px;
    padding-right: 5px;
    object-fit: contain;
  }
  @include media('screen', '<minWidth') {
    height: 21px;
    padding-right: 5px;
    object-fit: contain;
  }
}

.icon-comment-alt-label {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    object-fit: contain;
    font-family: Kanit;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    object-fit: contain;
    font-family: Kanit;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
  }
  @include media('screen', '<minWidth') {
    object-fit: contain;
    font-family: Kanit;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    align-items: center;
  }
}

.icon-comment-alt-edit {
  height: 26px;
  padding-right: 5px;
  object-fit: contain;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    height: 26px;
    padding-right: 5px;
    object-fit: contain;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    height: 26px;
    padding-right: 5px;
    object-fit: contain;
  }
  @include media('screen', '<minWidth') {
    height: 21px;
    padding-right: 5px;
    object-fit: contain;
  }
}

.icon-stop-watch {
  height: 26px;
  width: fit-content;
  object-fit: contain;
  color: #f96b57;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    height: 26px;
    width: auto;

    object-fit: contain;
    color: #f96b57;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    height: 26px;
    width: auto;

    object-fit: contain;
    color: #f96b57;
  }
  @include media('screen', '<minWidth') {
    height: 22px;
    width: auto;
    object-fit: contain;
    color: #f96b57;
  }
}

.span-tags {
  width: auto;
  object-fit: contain;
  font-size: 16px;
  color: #0c4da2;
  flex: 1;
  padding-left: 3px;
  color: #0c4da2;
  font-weight: bold;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: auto;
    object-fit: contain;
    font-size: 16px;
    flex: 1;
    padding-left: 3px;
    color: #0c4da2;
    font-weight: bold;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: auto;
    object-fit: contain;
    font-size: 16px;
    flex: 1;
    padding-left: 3px;
    color: #0c4da2;
    font-weight: bold;
  }
  @include media('screen', '<minWidth') {
    width: auto;
    object-fit: contain;
    font-size: 11px;
    flex: 1;
    padding-left: 3px;
    color: #0c4da2;
    font-weight: bold;
  }
}

.icon-tag-duo-tone-container {
  display: flex;
  align-items: center;
}

.icon-tag-duo-tone {
  height: 26px;
  margin-right: 5px;
  object-fit: contain;
  color: #0c4da2;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth', '>maxWidth') {
    height: 26px;
    width: auto;
    object-fit: contain;
    color: #0c4da2;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    height: 26px;
    width: auto;
    object-fit: contain;
    color: #0c4da2;
  }
  @include media('screen', '<minWidth') {
    height: 15px;
    width: auto;
    object-fit: contain;
    color: #0c4da2;
  }
}

.progress-list-filter {
  width: 200px;
  padding-left: 8px;
  padding-right: 8px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    width: 200px;
    padding-left: 8px;
    padding-right: 8px;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    width: 200px;
    padding-left: 8px;
    padding-right: 8px;
  }
  @include media('screen', '<minWidth') {
    width: auto;
    min-width: 110px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

.progress-containerStyles {
  height: 24px;
  width: 100%;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
    height: 24px;
    width: 100%;
  }
  @include media('screen', '>minWidth', '<bp1Width') {
    height: 24px;
    width: 100%;
  }
  @include media('screen', '<minWidth') {
    height: 17px;
    width: 100%;
  }
}

.Rectangle-516-web {
  width: 2px;
  height: 31px;
  margin: 6px 24px 20px 20px;
  background-color: #e5e6ee;
}
.Select-input > input {
  // max-width: X - 50px; // where X is the max width that you want
}
.css-yk16xz-control {
  //  width: 172px !important
}

.Rounded-Rectangle-6-web {
  width: fit-content;
  height: 44px;
  margin: 0 10px 20px 0px;
  padding: 3px 18px 4px 3px;
  object-fit: contain;
  border-radius: 27px;
  box-shadow: 3.9px 1px 6px 0 rgba(137, 148, 169, 0.4);
  background-color: #ffffff;
}

.score-tracking-label-web {
  width: 52px;
  height: 19px;
  margin: 16px 0 12px 9px;
  font-family: Kanit;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: left;
  color: #0c4da2;
}

.no-label-web {
  width: fit-content;
  height: 17px;
  font-family: Kanit;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 3.3;
  letter-spacing: normal;
  text-align: left;
  color: #0c4da2;
  padding-right: 0.5pc;
}
// .filter {
//   width: 1204px;
//   height: 606px;
//   margin: 0 91px 268px 0;
//   padding: 450px 0 0 200px;
//   object-fit: contain;
// }

.Rounded-Rectangle-4-web {
  width: 425px;
  // height: 413px;
  // margin: 25px 0 0 1px;
  padding: 21px 1px 30px 3px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
  border: solid 2px #eff1f5;
  background-color: #ffffff;
}

.Rounded-Rectangle-Group-4 {
  width: fit-content;
  height: 270px;
  margin: 23px 197px 0px 10px;
  padding: 22px 1px 21px 3px;
  object-fit: contain;
  box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
  border: solid 2px #eff1f5;
  background-color: #ffffff;
}

.label-card-web {
  width: 143px;
  height: 27px;
  margin: 15px 20px 23px 20px;
  object-fit: contain;
  font-family: Kanit;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.92;
  letter-spacing: normal;
  text-align: left;
  color: #0c4da2;
}

.filter-input-select-web {
  padding-top: 1pc;
  width: 172px;
}

.Layer-18-web {
  width: 40px;
  height: 40px;
  margin: 3px 10px 0px 0px;
  object-fit: contain;
}

.Aumpawa {
  width: fit-content;
  height: 14px;
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}

.card-one {
  position: relative;
}

.card-two {
  position: relative;
  z-index: 2;
}

.card-three {
  position: relative;
  z-index: 1;
  margin-top: -35px;
  margin-left: 1.5pc;
}

.card-four {
  position: relative;
  z-index: -1;
  margin-top: -35px;
  margin-left: 2.5pc;
  margin-bottom: 2pc;
}

.card-list-shadow-web {
  box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
  border: solid 2px #eff1f5;
  background-color: #ffffff;
}

.project-container-home-page {
  .project-timeline-container {
    border-radius: 10px;
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    .project-timeline-body-top {
      padding: 20px;
      border-bottom: 1px solid #eff1f5;
      .project-date {
        color: #333333;
        font-size: 0.8rem;
      }
      .project-name {
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.48;
        letter-spacing: normal;
        text-align: left;
        color: #387436;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 20px;
        overflow: hidden;
      }
      .project-type {
        color: #0c4da2;
      }
    }
    .project-timeline-body-bottom {
      padding: 20px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  .project-timeline-detail {
    border-radius: 10px;
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    .project-timeline-body-top {
      padding: 10px;
      border-bottom: 1px solid #eff1f5;
      .project-date {
        color: #333333;
        font-size: 0.6rem;
      }
      .project-name {
        font-size: 0.8rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.48;
        letter-spacing: normal;
        text-align: left;
        color: #387436;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 20px;
        overflow: hidden;
      }
      .project-type {
        color: #0c4da2;
      }
    }
    .project-timeline-body-bottom {
      padding: 10px;
      > div {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .description {
        padding: 10px;
        object-fit: contain;
        border-radius: 10px;
        border: solid 2px #eff1f5;
        background-color: #f1f3f7;
        > p {
          color: #6d7481;
          font-size: 0.6rem;
        }
      }
      .file-container {
        border: solid 2px #eff1f5;
        background-color: #ffffff;
        padding: 10px;
        .filename {
          color: #959aa5;
          font-size: 0.6rem;
          span.filename-text {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            max-height: 20px;
            overflow: hidden;
          }
          svg {
            margin-right: 5px;
          }
        }
        .download {
          color: #387436;
          font-size: 0.6rem;
          svg {
            margin-right: 5px;
          }
        }
      }
      .check-container {
        padding: 10px;
        object-fit: contain;
        border-radius: 10px;
        border: solid 2px #eff1f5;
        background-color: #f1f3f7;
        label {
          color: #6d7481;
          font-size: 0.6rem;
        }
      }
      .process-update {
        padding: 5px;
        padding-left: 10px;
        padding-right: 10px;
        object-fit: contain;
        border-radius: 10px;
        background-color: #f7dcdc;
        span {
          color: #b33030;
          font-size: 0.7rem;
        }
      }
    }
  }

  .process-step {
    color: #666666;
    border-radius: 15px;
    background-color: #e5e6ee;
    padding: 5px;
    margin-right: 5px;
    white-space: nowrap;
    margin-top: 10px;
  }
  .process-step.pass {
    background-color: #b4d0b3;
  }

  .process-step.active {
    background-color: #387436;
    color: white;
  }
}

.discuss-container {
  align-items: center;
  img {
    width: 32px;
    height: 27px;
  }
  label {
    font-weight: bold;
    font-size: 12px;
  }
  svg {
    width: 32px;
    height: 27px;
    font-size: 24px;
    margin-right: 10px;
    margin-bottom: 3px;
  }
}
