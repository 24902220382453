#listening-v2 {
    .card-type-filter {
        padding: 10px 20px;
        margin-right: 10px;
        object-fit: contain;
        border-radius: 27px;
        box-shadow: 3.9px 1px 6px 0 rgba(137, 148, 169, 0.4);
        background-color: #fff;
        white-space: nowrap;
    }
}
