.card-survey-mobile {
  .container-survey {
    min-height: 224px;
    margin: 15px 14px 0px 12px;
    padding: 14px 10px 14px 10px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
    border: solid 2px #eff1f5;
    background-color: #ffffff;
  }
  .favorite-icon {
    width: 36px;
    height: 36px;
    border-radius: 18px;
    box-shadow: 2.8px 2.8px 5px 0 rgba(137, 148, 169, 0.2), -2.1px -2.1px 8px 0 rgba(137, 148, 169, 0.15),
      inset 3.5px 3.5px 10px 0 #ffffff, inset -3.5px -3.5px 10px 0 rgba(188, 194, 211, 0.25);
    background-color: #ffffff;
  }
  .law-icon {
    width: 40px;
    height: 40px;
    // padding: 18px 22px 20px 31px;
    border-radius: 20px;
    border: solid 3px #85b0e8;
    background-color: #0c4da2;
  }
  .agency-container {
    height: 30px;
    margin: 0px 0px 0px 5px;
    padding: 10px;
    object-fit: contain;
    border-radius: 15px;
    box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15), inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
    background-color: #f4f6f9;
    display: flex;
    justify-content: center;
    align-items: center;
    label {
      font-size: 12px;
    }
    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
  .project-name {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    overflow: unset;
    text-overflow: unset;
    display: initial;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    max-height: unset !important;
    color: #0c4da2;
  }
  .survey-name {
    font-size: 14px;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 40px;
    color: #666666;
    margin-top: 10px;
  }
  .text-status {
    color: #488345;
    font-size: 12px;
  }
  .user-status {
    margin-right: 10px;
  }
  .text-number {
    color: #494949;
    font-size: 12px;
    margin-right: 5px;
    font-weight: bold;
  }
  @media screen and (max-width: 320px) {
    .text-status {
      color: #488345;
      font-size: 12px;
    }
    .user-status {
      margin-right: 6px;
    }
    .text-number {
      color: #494949;
      font-size: 9px;
      margin-right: 0px;
      font-weight: bold;
    }
  }
  @media screen and (min-width: 390px) and (max-width: 767px) {
    .text-status {
      color: #488345;
      font-size: 12px;
    }
    .user-status {
      margin-right: 6px;
    }
    .text-number {
      color: #494949;
      font-size: 9px;
      margin-right: 0px;
      font-weight: bold;
    }
  }
}

#project-card-container-v2-mobile-timeline {
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 20px;
  display: flex;

  .discuss-container img {
    width: 18px;
    height: 16px;
  }
  .survey-card-container {
    flex: 1;
    max-width: 100%;
    .survey-card {
      background-color: #fff;
      padding: 10px;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      .row1 {
        align-items: center;
        .project-type {
          display: flex;
          svg {
            height: 18px;
            width: 21px;
            color: #0c4da2;
          }
          span {
            margin-left: 5px;
            color: #0c4da2;
            font-weight: bold;
            font-size: 14px;
          }
        }
        .organize-container img {
          width: 26px;
          object-fit: contain;
          height: 26px;
          margin-right: 5px;
        }
      }
      .column2 {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        flex: 1;
        .title {
          font-size: 16px;
          font-weight: 600;
          margin-top: 10px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.36;
          letter-spacing: normal;
          text-align: left;
          color: #0c4da2;
        }
        .webboard-row {
          margin-top: 10px;
          display: flex;
          font-size: 12px;
          .lawgroup-text {
            max-width: 250px;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          label {
            font-size: 12px;
          }
          span {
            font-size: 12px;
          }
          .badge-answer {
            margin-left: 5px;
            padding: 2px 7.5px;
            border-radius: 5px;
            background-color: #488345;
            color: #fff;
          }
          .badge-public {
            margin-left: 5px;
            padding: 2px 7.5px;
            border-radius: 5px;
            background-color: #0e4ea3;
            color: #fff;
          }
        }
        .process-container {
          margin-top: 15px;
          svg {
            font-size: 16px;
            margin-right: 10px;
            color: #387436;
          }
          span {
            color: #387436;
          }
        }
        .expand {
          align-items: center;
          padding-left: 7.5px;
          border-left: 2px solid #e5e6ee;
          .badge-public {
            margin-right: 5px;
            padding: 2px 7.5px;
            border-radius: 5px;
            background-color: #0e4ea3;
            color: #fff;
          }
        }
        .progressbar-row {
          display: flex;
          flex: 1;
          align-self: flex-end;
          justify-content: flex-end;
          justify-items: flex-end;
          align-items: flex-end;
          margin-top: 10px;
          width: 100%;
          span {
            white-space: nowrap;
          }
          span,
          label {
            color: #666666;
          }
        }
        .project-timeline-body-bottom {
          margin-top: 15px;
          > div {
            flex: 1;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            // justify-content: space-between;
            font-size: 12px;
            .process-step {
              color: #666666;
              border-radius: 15px;
              background-color: #e5e6ee;
              padding: 5px;
              margin-right: 2px;
              white-space: nowrap;
              margin-top: 10px;
              font-size: 12px;
            }
            .process-step.pass {
              background-color: #b4d0b3;
            }

            .process-step.active {
              background-color: #387436;
              color: white;
            }
          }
        }
      }
    }
    .survey-card.law-cancal {
      background-color: #efefef;
      opacity: 0.8;
      .law-group-container-mobile {
        border: solid 5px #eef0fb !important;
        background-color: #e5e6ee !important;
        img {
          opacity: 0.8;
        }
      }
      .title,
      .entity-text {
        color: #666666 !important;
      }
      .process-container {
        svg {
          color: #666666 !important;
        }
      }
      .process-step {
        background-color: #cfcfcf !important;
        color: #333 !important;
      }
      .process-step.active {
        background-color: #333 !important;
        color: #fff !important;
      }
      .project-type {
        svg {
          color: #666666 !important;
        }
        span {
          color: #666666 !important;
        }
      }
    }
    .law_publish_title {
      padding: 3px 10px;
      align-items: center;
      display: flex;
      border-radius: 22px;
      background-color: #e3d6bc;
      span.ant-avatar {
        margin-right: 10px;
      }
      font-size: 12px;
      margin-bottom: 10px;
    }
    .law_publish_link {
      color: #9c824f;
      font-weight: bold;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
