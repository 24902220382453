// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 480px;

@function translate-media-condition($c) {
    $condMap: (
        "screen": "only screen",
        "print": "only print",
        "retina":
            "(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)",
        ">maxWidth": "(min-width: #{$mediaMaxWidth + 1})",
        "<maxWidth": "(max-width: #{$mediaMaxWidth})",
        ">bp1Width": "(min-width: #{$mediaBp1Width + 1})",
        "<bp1Width": "(max-width: #{$mediaBp1Width})",
        ">minWidth": "(min-width: #{$mediaMinWidth + 1})",
        "<minWidth": "(max-width: #{$mediaMinWidth})",
    );
    @return map-get($condMap, $c);
}
@mixin media($args...) {
    $query: "";
    @each $arg in $args {
        $op: "";
        @if ($query != "") {
            $op: " and ";
        }
        $query: $query + $op + translate-media-condition($arg);
    }
    @media #{$query} {
        @content;
    }
}

.progress-containerStyles {
    height: 24px;
    width: 100%;
    @include media("retina") {
    }
    @include media("screen", ">bp1Width", "<maxWidth") {
        height: 24px;
        width: 100%;
    }
    @include media("screen", ">minWidth", "<bp1Width") {
        height: 24px;
        width: 100%;
    }
    @include media("screen", "<minWidth") {
        height: 17px;
        width: 100%;
    }
}
