#project-card-container-v2 {
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 50px;
    display: flex;
    .project-id-container {
        margin-right: 20px;
    }
    .filter-container {
        min-width: 300px;
        margin-left: 50px;
        margin-bottom: 20px;
        .search {
            .ant-input {
                height: 50px;
                border-radius: 5px 0px 0px 5px;
            }
            button {
                height: 50px;
                width: 50px;
                border-radius: 0px 5px 5px 0px;
            }
        }
    }
    .survey-card-container {
        flex: 1;

        .survey-card {
            background-color: #fff;
            padding: 20px;
            display: flex;
            background-color: #fff;
            min-height: 230px;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
            .column1 {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                .project-type {
                    display: flex;
                    svg {
                        height: 18px;
                        width: 21px;
                        color: #0c4da2;
                    }
                    span {
                        margin-left: 5px;
                        color: #0c4da2;
                        font-weight: bold;
                        font-size: 14px;
                    }
                }
                .organize-container img {
                    width: 26px;
                    object-fit: contain;
                    height: 26px;
                    margin-right: 5px;
                }
            }
            .column2 {
                display: flex;
                flex-direction: column;
                flex: 1;
                .title {
                    margin-top: 10px;
                    font-size: 20px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.36;
                    letter-spacing: normal;
                    text-align: left;
                    color: #0c4da2;
                }
                .webboard-row {
                    margin-top: 15px;
                    display: flex;
                    font-size: 14px;
                }
                .badge-public {
                    margin-right: 5px;
                    padding: 2px 7.5px;
                    border-radius: 5px;
                    background-color: #0e4ea3;
                    color: #fff;
                }
                .expand {
                    align-items: center;
                    padding-left: 7.5px;
                    border-left: 2px solid #e5e6ee;
                }
                .process-container {
                    margin-top: 15px;

                    svg {
                        font-size: 20px;
                        margin-right: 10px;
                        color: #387436;
                    }
                    span {
                        color: #387436;
                        font-size: 18px;
                    }
                }
                .project-timeline-body-bottom {
                    > div {
                        flex: 1;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        // justify-content: space-between;
                        font-size: 18px;
                        .process-step {
                            color: #666666;
                            border-radius: 15px;
                            background-color: #e5e6ee;
                            padding: 5px 10px;
                            margin-right: 5px;
                            white-space: nowrap;
                            margin-top: 10px;
                        }
                        .process-step.pass {
                            background-color: #b4d0b3;
                        }

                        .process-step.active {
                            background-color: #387436;
                            color: white;
                        }
                    }
                }
            }
        }
    }
}
