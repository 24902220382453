.tab-menu-userprofile-page-container {
  .tab-menu-userprofile-page {
    display: flex;
    padding: 15px 10px;
    margin-top: 10px;
    background-color: #eff1f4;
    > div {
      label {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    div {
      flex: 1;
      opacity: 0.3;
      img {
        height: 20px;
        width: 20px;
      }
      label {
        margin-top: 10px;
        font-size: 12px;
      }
    }
    div.active {
      opacity: 1;
    }
  }
  .userprofile-menu {
    .title {
      color: #0c4ea4;
      font-size: 14px;
      font-weight: bold;
    }
    .userprofile-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      .userprofile-tab-detail {
        // padding: 10px;
        flex: 1;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 3px solid #e5e6ee;
        opacity: 0.4;
        img {
          width: 14px;
          height: 17px;
          margin-right: 10px;
        }
        span {
          font-size: 14px;
          font-weight: bold;
          color: #0c4da2;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      .userprofile-tab-detail.active {
        border-bottom: 3px solid #0c4da2;
        opacity: 1;
      }
    }
    .user-profile-body-2 {
      padding: 20px;
      div.icon-up-verify {
        .icon-user {
          width: 60px;
          height: 60px;
        }
        .badge-check-duotone-userprofile {
          margin-left: 60px;
        }
      }
    }
    .law-container {
      width: 100%;
      border-radius: 10px;
      border: solid 2px #eff1f5;
      padding: 10px 20px;
      button,
      input,
      select,
      textarea,
      a,
      p,
      label,
      span,
      td,
      tr,
      pre,
      div {
        font-family: BaiJamjuree !important;
      }

      h1,
      h2,
      h3 {
        font-family: BaiJamjuree-SemiBold;
      }

      h4,
      h5 {
        font-family: BaiJamjuree-Bold;
      }
      .description {
        font-family: BaiJamjuree;
        font-size: 14px;
        letter-spacing: normal;
        text-align: left;
        color: #666;
      }
      .title-name {
        font-weight: bold;
        color: #00308f;
        font-size: 18px;
      }
      div.group {
        // padding: 5px 15px;
        justify-content: center;
        align-items: center;
        display: flex;
        div {
          position: absolute;
          white-space: nowrap;
          bottom: -30px;
          padding: 10px;
          border-radius: 10px;
          background-color: #000;
          color: white;
          z-index: 200;
        }
        button {
          color: #3562fd;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          display: flex;
        }
        button:disabled {
          opacity: 0.3;
        }
      }
      div.group.active {
        // padding: 5px 5px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-right: solid 1px #eff1f5;
        background-color: #0c4da2;
        color: white;
      }
      div.group.last {
        border-right: hidden;
      }
      div.group.disabled {
      }
    }
  }
  .notification-menu {
    .notification-menu-header {
      padding: 20px;
      border-bottom: 5px solid #e5e6ee;
      label {
        font-weight: bold;
      }
    }
    .notification-setting-card {
      padding: 0px 28px 0px 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0px;
      background-color: #ffffff;
      border: none;
      height: 70px;
      border-bottom: 1px solid #e5e6ee;
      span {
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
  .survey-history-menu {
    .survey-history-menu-header {
      padding: 20px;
      border-bottom: 5px solid #e5e6ee;
      label {
        font-weight: bold;
      }
    }
    .survey-card {
      margin-bottom: 10px;

      .survey-card-header {
        align-items: center;
        justify-content: space-between;
        img {
          height: 16px;
          width: 16px;
          margin-right: 5px;
        }
        img.img-eye {
          width: 22px;
        }
      }
      .survey-card-body {
        margin-top: 10px;
        padding: 10px;
        border-radius: 10px;
        border: solid 3px #eff1f5;
        label.project-name {
          font-size: 14px;
          font-weight: bold;
        }
        label.survey-name {
          font-size: 13px;
          color: #666666;
        }
        .agency-card {
          .organize-container {
            img {
              height: 20px;
              width: 20px;
            }
          }
          .status-card {
            display: flex;
            align-items: center;
            img {
              height: 30px;
              width: 24px;
            }
          }
        }
        .progress-containerStyles {
          > div {
            > span {
              font-size: 12px !important;
            }
          }
        }
      }
    }
  }
  .webboard-history-menu {
    .webboard-history-menu-header {
      padding: 20px;
      border-bottom: 5px solid #e5e6ee;
      label {
        font-weight: bold;
      }
    }
    .webboard-card {
      margin-bottom: 10px;

      .webboard-card-header {
        align-items: center;
        justify-content: space-between;
        img {
          height: 16px;
          width: 16px;
          margin-right: 5px;
        }
        img.img-eye {
          width: 22px;
        }
      }
      .webboard-card-body {
        margin-top: 10px;
        padding: 10px;
        border-radius: 10px;
        border: solid 3px #eff1f5;
        label.project-name {
          font-size: 14px;
          font-weight: bold;
        }
        .record-body-inside-border-container {
          margin: 0px;
          border-radius: 5px;
          padding: 10px;
          font-size: 12px !important;
        }
        .record-bottom-border-container {
          box-sizing: border-box;
          height: 30px;
          border: 1px solid #0c4da2;
          border-radius: 5px;
          padding: 10px 20px;
          width: initial;
          margin: 0;
          cursor: pointer;
          font-size: 12px !important;
          align-items: center;
          display: flex;
          justify-content: flex-start;
          color: #0c4da2;
          img {
            height: 14px;
            margin-right: 10px;
          }
        }
      }
    }
  }
}
