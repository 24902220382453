#webview-page {
  padding-bottom: 20px;
  .menu-top {
    margin-bottom: 20px;
    div {
      padding: 5px 20px;
      border-radius: 20px;
      border: solid 2px #eff1f5;
      background-color: #ffffff;
    }
  }
  .rule-container {
    margin-top: 20px;
    padding: 15px 20px;
    border-radius: 20px;
    border: solid 2px #eff1f5;
    background-color: #ffffff;
    .title {
      font-size: 20px;
      flex: 1;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: #0c4da2;
      padding-bottom: 10px;
      border-bottom: 1px solid #eff1f5;
      margin-bottom: 10px;
    }
  }
  .rule-content {
    .title-content {
      font-size: 14px;
      flex: 1;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      text-align: left;
      color: #0c4da2;
    }
  }
}
