// Predefined Break-points
// Predefined Break-points
$mediaMaxWidth: 1260px;
$mediaBp1Width: 960px;
$mediaMinWidth: 436px;

@function translate-media-condition($c) {
  $condMap: (
    'screen': 'only screen',
    'print': 'only print',
    'retina':
      '(-webkit-min-device-pixel-ratio: 1.5), (min--moz-device-pixel-ratio: 1.5), (-o-min-device-pixel-ratio: 3/2), (min-device-pixel-ratio: 1.5), (min-resolution: 120dpi)',
    '>maxWidth': '(min-width: #{$mediaMaxWidth + 1})',
    '<maxWidth': '(max-width: #{$mediaMaxWidth})',
    '>bp1Width': '(min-width: #{$mediaBp1Width + 1})',
    '<bp1Width': '(max-width: #{$mediaBp1Width})',
    '>minWidth': '(min-width: #{$mediaMinWidth + 1})',
    '<minWidth': '(max-width: #{$mediaMinWidth})',
  );
  @return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
  $query: '';
  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }
  @media #{$query} {
    @content;
  }
}

/**
 * Usage examples
 */

.profile-section-1-container {
  padding-left: 196px;
  padding-top: 150px;
  width: fit-content;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.profile-section-2-container {
  padding-top: 150px;
  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.card-section-2-container-01 {
  position: absolute;
  z-index: -1;
}

.card-section-2-container-02 {
  position: absolute;
  z-index: 2;
  padding-top: 5.6pc;
}

.card-section-2-container-03 {
  // position: absolute;
  // margin-left: -23px;
}

.add-image-01 {
  position: absolute;
  z-index: 1;
  padding-left: 164px;
  padding-top: 42px;
}
.add-image-02 {
  position: absolute;
  z-index: 1;
  padding-left: 275px;
  padding-top: 175px;
  cursor: pointer;
}

.label-all-container {
  margin-top: 20px;
}

.label-01 {
  object-fit: contain;
  font-family: Kanit;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-left: 40px;
}

.label-02 {
  object-fit: contain;
  font-family: Kanit;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
  padding-left: 40px;
}

.badge-check-duotone-01 {
  width: 40px;
  height: 31px;
  object-fit: contain;
  color: #8e99ad;
  padding-left: 1pc;
}

.label-underline {
  text-decoration: underline;
  color: #8e99ad;
}

hr.new1 {
  border-top: 1px solid #e5e6ee;
  width: 414px;
}

.section-padding-01 {
  padding-top: 1pc;
}

.label-menu {
  object-fit: contain;
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #1a57a7;
  padding-left: 2pc;
  cursor: pointer;
}

.label-menu:hover {
  text-decoration: underline;
}

.comment-alt-edit-duotone-4-userprofile {
  width: 31px;
  height: 31px;
  object-fit: contain;
  color: #0c4da2;
  margin-left: 3pc;
}

.card-shadow-container {
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
  border: solid 2px #eff1f5;
  background-color: #ffffff;

  @include media('retina') {
  }
  @include media('screen', '>bp1Width', '<maxWidth') {
  }
  @include media('screen', '>minWidth', '<bp1Width') {
  }
  @include media('screen', '<minWidth') {
  }
}

.card-shadow-container03 {
  object-fit: contain;
  border-bottom: 10px;
  box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
  border: solid 2px #eff1f5;
  background-color: #ffffff;
}

.card-shadow-container01 {
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 1px 3.9px 16px 0 rgba(137, 148, 169, 0.24);
  border: solid 2px #eff1f5;
  background-color: #ffffff;
}

.card-step {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
    flex: 1;
  }
  > div.active {
    border-bottom: 5px solid #0c4da2;
  }
}

.userprofile-1 {
  width: 100%;
  min-width: 220;
  height: 25px;
  margin: 0px 0px 0px 0px;
  object-fit: contain;
  border-bottom: 5px solid #eff1f5;
  border-radius: 1.8px;
}

.userprofile-2 {
  width: 100%;
  min-width: 220;
  height: 25px;
  margin: 0px 0px 0px 0px;
  object-fit: contain;
  border-bottom: 5px solid #0c4da2;
  border-radius: 1.8px;
}

.tab-label-userprofile {
  object-fit: contain;
  font-family: Kanit;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c4da2;
  cursor: pointer;
}

.userprofile-lavelup-label {
  object-fit: contain;
  font-family: Kanit;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 7;
  letter-spacing: normal;
  color: #0c4ea4;
  display: flex;
  justify-content: center;
  position: relative;
}

.userprofile-lavelup-label2 {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0 auto;
  max-width: 600px;
  height: 100px;
  color: #0c4da2;
  border-radius: 3px;
  padding-top: 50px;
}

.idcard-label-center-container {
  padding-top: 50px;
}

.userprofile-lavelup-label3 {
  object-fit: contain;
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  margin: 0 auto;
  max-width: 700px;
  display: flex;
  align-items: center;
  color: #ccc;
  border-radius: 3px;
  line-height: 4;
}

.userprofile-lavelup-label4 {
  object-fit: contain;
  font-family: Kanit;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0c4da2;
  display: flex;
  justify-content: flex-start;
  padding-left: 178px;
  padding-top: 17px;
}

.userprofile-lavelup-label5 {
  object-fit: contain;
  font-family: Kanit;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #6d7481;
  display: flex;
  justify-content: flex-start;
  padding-top: 15px;
  padding-left: 71px;
}

.userprofile-lavelup-button {
  width: 190px;
  height: 45px;
  object-fit: contain;
  border-radius: 10px;
  box-shadow: 4.9px 4.9px 30px 0 rgba(8, 66, 141, 0.3),
    -6.6px -4.6px 30px 0 rgba(39, 176, 223, 0.25),
    inset 6.9px 1.1px 18px 0 rgba(17, 107, 223, 0.36),
    inset -9.2px -9.2px 26px 0 rgba(12, 77, 162, 0.15);
  background-image: linear-gradient(to top, #0c4da2, #0c4da2, #175bb4);
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  margin-left: 179px;
  margin-top: 48px;
  padding: 10px 0 0 52px;
  font-size: 16px;
}

.userprofile-lavelup-input1 {
  // position: absolute;
  // padding-top: 23%;
  // padding-left: 10%;
  // width: 83%;
  width: 90%;
  margin: 0 auto;
  max-width: 700px;
  height: 100px;
  border-radius: 3px;
}

.userprofile-lavelup-input-disable1 {
  position: absolute;
  padding-top: 23%;
  padding-left: 2%;
  width: 90%;
}

.user-circle-duotone-1-copy-userprofile {
  width: 98px;
  height: 98px;
  object-fit: contain;
  color: #0c4da2;
}

.chevron-double-right-duotone-userprofile {
  width: 24px;
  height: 23px;
  object-fit: contain;
  color: #8e99ad;
  margin: 2pc;
}

.badge-check-duotone-userprofile {
  width: 27px;
  height: 27px;
  object-fit: contain;
  color: #8e99ad;
  position: absolute;
  margin-top: -5px;
  margin-left: 90px;
}

.userprofile-placeholder {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0c4da2;
}
.userprofile-placeholder::placeholder {
  color: #0c4da2;
}

.credit-card-duotone-userprofile {
  display: flex;
  justify-content: flex-start;
  padding-left: 225px;
  padding-top: 56px;
}

.Group-7-id-card {
  width: 345px;
  height: 199px;
  object-fit: contain;
  display: flex;
  justify-content: flex-start;
  margin-top: 111px;
}

/* Float four columns side by side */
.column01 {
  // float: left;
  // width: 508px;
  // padding: 0 10px;
  // margin-bottom: 50px;
}

.column02 {
  margin-left: 20px;
  width: 950px;
  // float: left;
  // width: 850px;
  // padding: 0 10px;
}
/* Remove extra left and right margins, due to padding */
.row {
  margin: 0 -5px;
}

/* Clear floats after the columns */
.row:after {
  content: '';
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column01 {
    width: 100%;
    display: block;
    margin-bottom: 50px;
  }
  .column02 {
    width: 100%;
    display: block;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1024px) {
  .column02Alter {
    width: 980px;
    display: block;
    margin-top: 30px;
    margin: 0 0 0 50px;
  }
}

@media screen and (max-width: 1600px) {
  .column02 {
    width: 100%;
    display: block;
    margin-top: 30px;
    position: relative;
    margin-left: -8px;
  }

  .column02Alter {
    width: 980px;
    display: block;
    margin-top: 30px;
    position: relative;
    margin-left: -8px;
  }
  .alter-card02 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    // overflow-x: auto;
    // max-height: 1168px;
    text-align: center;
    background-color: #ffffff;
  }

  .record-card02 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    height: 1000px;
    text-align: center;
    background-color: #ffffff;
  }
}

.record-card02 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 1000px;
  text-align: center;
  background-color: #ffffff;
}
.alter-card02 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  // max-height: 1168px;
  // overflow-x: auto;
  text-align: center;
  background-color: #ffffff;
}

@media (min-width: 600px) {
  .MuiContainer-root {
    padding-left: 196px !important;
    padding-right: 196px !important;
  }
}

/* Style the counter cards */
.card01 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 773px;
  text-align: center;
  background-color: #ffffff;
}

.card02 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  // height: 1000px;
  // overflow: auto;
  text-align: center;
  background-color: #ffffff;
}

.card03 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 20px;
  height: 93px;
  margin-bottom: 172px;
  text-align: center;
  background-color: #ffffff;
}

.container {
  margin: 0 auto;
  max-width: 48rem;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  background-color: #2196f3;
  width: fit-content;
  padding: 10px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 170px;
  font-size: 50px;
  text-align: center;
}

.badge-check-duotone-profile {
  width: 35px;
  height: 36px;
  margin: 1px 10px 12px 28px;
  object-fit: contain;
  color: #0c4da2;
}

.tab3-label-01-userprofile {
  object-fit: contain;
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0c4ea4;
  display: flex;
  justify-content: flex-start;
  padding: 20px 0 16px 40px;
}

.tab3-label-02-userprofile {
  object-fit: contain;
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0c4ea4;
  display: flex;
  justify-content: flex-start;
  padding: 32px 0 16px 40px;
}

.tab3-label-03-userprofile {
  object-fit: contain;
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0c4ea4;
  display: flex;
  justify-content: flex-start;
  padding: 0 0 14px 40px;
}

.Rounded-Rectangle-6-copy-11-tab3-userprofile {
  padding-left: 40px;
  // width: 874px !important;
}

.Rounded-Rectangle-6-copy-11-tab3-userprofile-input::placeholder {
  color: #697288;
}

.Possitive-Message-userprofile {
  object-fit: contain;
  font-family: Kanit;
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #51924e;
  display: flex;
  justify-content: flex-start;
  padding: 16px 0 0 40px;
}

.SC404 {
  margin: 0 10px 0 0;
}

.radio-userprofile {
  display: flex;
  justify-content: flex-start;
  padding: 24px 0 0 40px;
}

.radio-loop {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0 0 40px;
}

.Selected-copy-userprofile {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}

.Inactive {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}

label {
  display: flex;
  justify-content: flex-start;
  object-fit: contain;
  font-family: Kanit;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #0c4ea4;
}

.userprofile-old {
  padding-left: 40px;
}

.userprofile-old-input {
  // width: 415px;
  min-width: 200px;
  margin: 14px 0 0 40px;
}

.userprofile-sex-input {
  // width: 410px;
  min-width: 200px;
  margin: 14px 0 0 40px;
}

.userprofile-sex {
  padding: 0 0 0 43px;
}

.userprofile-education {
  padding: 27px 0 0 40px;
}

.userprofile-error {
  padding: 27px 0 16px 40px;
}

.userprofile-education-input {
  padding: 16px 0 0 40px;
}

.input-label {
  object-fit: contain;
  font-family: Kanit;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  padding: 0 35px 0 40px;
}

.gray {
  color: #6d7481;
}

.alterheader {
  display: flex;
  align-items: center;
}

.alterheaderImage {
  margin: 10px 14px 0 44px;
}

.alterheaderLabel {
  margin: 10px 0 0 0;
}

.alterheaderSelect {
  width: 203px;
  margin: 10px 0 0 370px;
}

.recordheaderSelect {
  width: 203px;
  margin: 10px 0 0 470px;
  float: right;
}

.alterheaderborderbottom {
  border-bottom: solid #e5e6ee;
  position: relative;
  width: 978px;
  margin: 20px 0 0 -18px;
}

.alterbodycontainer {
  display: flex;
  align-items: center;
}

.alterbodytimelabel {
  margin: 10px 10px 0 9px;
  text-align: left;
  color: #333333;
  position: relative;
}

.clock-solid-alter {
  width: 27px;
  height: 27px;
  object-fit: contain;
  color: #6d7481;
  margin: 10px 0 0 30px;
}

.eye-duotone-2 {
  width: 25px;
  height: 17px;
  object-fit: contain;
  color: #0c4da2;
  margin: 16px 8px 0 540px;
}

.subscribe-eye-duotone-2 {
  width: 25px;
  height: 17px;
  object-fit: contain;
  color: #0c4da2;
  margin: 16px 8px 0 460px;
}

.col-container {
  display: table;
  width: 20%;
}

.col-subtab1-container {
  display: table;
  width: 30%;
}
.col {
  display: table-cell;
  padding: 16px;
}

.col-clock-container {
  position: relative;
  margin-left: -10px;
}

.alter-body-eye-label {
  color: #0c4da2;
  padding: 16px 0 0 0;
}

.alter-body-border-container {
  box-sizing: border-box;
  width: 898px;
  height: 182px;
  border: 2px solid #eff1f5;
  margin: 0 0 0 20px;
  border-radius: 10px;
  padding: 25px 32px 25px 32px;
}

.subscribe-heart-circle-duotone-2 {
  width: 40px;
  height: 40px;
  object-fit: contain;
  color: #0c4da2;
  margin: 11px 0 0 0;
}

.subscribe-Rectangle-3-copy-5 {
  height: 40px;
  object-fit: contain;
  border-radius: 20px;
  background-color: #6d7481;
  display: flex;
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  margin: 0 0 0 13px;
}
.subscribe-Rectangle-3-copy-5-style {
  margin: 5px 0 0 13px;
}

.subscribe-books-duotone-1 {
  width: 24px;
  height: 21px;
  margin: 0 12px 0 0;
  object-fit: contain;
  color: #ffffff;
}

.subscribe-heart-circle-out {
  height: 62px;
  width: 62px;
  object-fit: contain;
  box-shadow: 2.8px 2.8px 5px 0 rgba(137, 148, 169, 0.2),
    -2.1px -2.1px 8px 0 rgba(137, 148, 169, 0.15),
    inset 3.5px 3.5px 10px 0 #ffffff,
    inset -3.5px -3.5px 10px 0 rgba(188, 194, 211, 0.25);
  background-color: #ffffff;
  border-radius: 50%;
  position: relative;
  margin: -10px 0 0 95px;
}

.record-body-border-container {
  box-sizing: border-box;
  width: 898px;
  // height: 255px;
  border: 2px solid #eff1f5;
  margin: 0 0 0 20px;
  border-radius: 10px;
  // padding: 25px 0 0 32px;
  padding: 20px;
}

.record-body-inside-border-container {
  box-sizing: border-box;
  // width: 830px;
  border: 2px solid #eff1f5;
  margin: 16px 0 0 0;
  padding: 20px;
  background-color: #eff1f5;
}

.alter-body-card-label01 {
}

.alter-body-card-label02 {
  float: left;
  padding: 14px 0 0 0;
  color: #666666;
}

.alterbodyborderbottom {
  border-bottom: solid #e5e6ee;
  position: relative;
  // width: 978px;
  margin: 75px 0 0 -33px;
}

.subscribebodyborderbottom {
  border-bottom: 1px solid #e5e6ee;
  position: relative;
  // width: 978px;
  margin: 10px 0 0 -33px;
}

.subscribebodyborderbottom02 {
  border-bottom: solid #e5e6ee;
  position: relative;
  margin: -22px 0 0 351px;
  width: 373px;
}

.recordbodyborderbottom {
  border-bottom: solid #e5e6ee;
  position: relative;
  // width: 978px;
  margin: 30px 0 0 -33px;
}

.alter-Rounded-Rectangle-6 {
  width: 93px;
  height: 40px;
  margin: 5px 37px 0 0px;
  padding: 2px 8px 3px 3px;
  object-fit: contain;
  border-radius: 20px;
  box-shadow: inset -1.7px -2.5px 6px 0 rgba(96, 134, 188, 0.15),
    inset 1.8px 0.9px 8px 0 rgba(105, 123, 157, 0.15);
  background-color: #f4f6f9;
}

.alter-Layer-4 {
  width: 35px;
  height: 35px;
  margin: auto 4px 0 0;
  object-fit: contain;
  align-items: center;
}

.alter-rountangle-body-container {
  display: flex;
  align-items: center;
}

.alter-rountangle-text {
  color: #697288;
}

.alter-start-date {
  margin: 0 15px 0 0;
}

.alter-end-date {
  margin: 0 0 0 15px;
}

.alter-vote-duotone {
  width: 36px;
  height: 25px;
  margin: 0 0 0 90px;
  object-fit: contain;
}

.alter-send-status {
  margin: 0 0 0 10px;
  font-weight: 200;
}

.record-card-semilabel {
  text-align: left;
  color: #0c4da2;
  cursor: pointer;
}

.record-card-semilabel:hover {
  text-decoration: underline;
  color: #0c4da2;
}

.record-bottom-border-container {
  box-sizing: border-box;
  width: 123px;
  height: 36px;
  border: 1px solid #0c4da2;
  border-radius: 5px;
  margin: 5px 25px 0 0;
  padding: 0 0 0 10px;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: flex-start;
  color: #0c4da2;
}

.record-bottom-border-container:hover {
  background-color: #f1f3f7;
}

.record-bottom-border-container:active {
  background-color: #f1f3ef;
}

.subscribetabbottomborder01 {
  border-bottom: solid #e5e6ee;
  // width: 339px;
  position: relative;
  margin: 0 0 0 -19px;
  padding: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  // padding: 0 0 0 141px;
}

.subscribetabbottomborder01:hover,
.subscribetabbottomborder01:active,
.subscribetabbottomborder01.active {
  border-bottom: solid #0c4da2;
  // width: 339px;
  position: relative;
  margin: 0 0 0 -10;
  // height: 58px;
  display: flex;
  align-items: center;
  color: #0c4da2;
}

.subscribetablabel {
  cursor: pointer;
  color: #8e99ad;
}

.subscribetablabel:active,
.subscribetablabel:hover {
  cursor: pointer;
  color: #0c4da2;
}

.subscribetabbottomborder03 {
  border-bottom: #e5e6ee;
}

.subscrie-landmark-duotone-4 {
  width: 32px;
  height: 32px;
  object-fit: contain;
  color: black;
  margin: 0 8px 0 0;
}

.record-search-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 31px 0 0 31px;
}

.record-search-swith {
  margin: 0 17px 0 21px;
}

.subscribe-balance-scale-light {
  width: 28px;
  height: 22px;
  margin: 0 10px 0 -13px;
  object-fit: contain;
  color: #387436;
  position: relative;
}

.subscribe-Rounded-Rectangle-3-copy-2 {
  object-fit: contain;
  border-radius: 22px;
  margin: 0px 11px 0px 0;
  position: relative;
  z-index: 1;
}

.subscribe-Rounded-Rectangle-3-copy-2-dash {
  object-fit: contain;
  border-radius: 22px;
}

.notification-setting-card {
  padding: 0px 28px 0px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: #ffffff;
  border: solid 3px #eff1f5;
  height: 89px;
  span.left {
    font-family: Kanit;
    font-size: 20px;
    font-weight: 'bold';
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0c4da2;
  }
}

.notification-info {
  border: 1px solid #efefef;
  padding-top: 20px;
  padding-bottom: 20px;
  flex: 1;
  align-items: center;
  justify-items: center;
  span {
    font-size: 18px;
  }
  svg {
    height: 30px;
    width: 30px;
    margin-right: 10px;
  }
}

.agency-reply p {
  margin-bottom: 0px;
}
